import { Contrast } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import { Box, Divider, Menu, MenuItem, useTheme, Badge, Tooltip } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import {
  HelpCircle,
  LogOut,
  MessageSquarePlus,
  Settings,
  ShieldCheck,
  Terminal,
  RefreshCw,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useUserStore } from "../../store/userStore";
import { ALLOWED_ROLES } from "../ProtectedRoutes/SettingsRoute";
import ThemeMenuItem from "../ThemeMenuItem/ThemeMenuItem";
import { useParams } from "react-router-dom";
import { useLogStore } from "../../store/logStore";

interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onLogout: () => void;
  onSettings: () => void;
  onFeedback: () => void;
  onPrivacyPolicy: () => void;
  onTermsOfService: () => void;
  onTechnicalSupport: () => void;
  onSalesSupport: () => void;
  onHelpCenter: () => void;
  onThemeChange: (mode: "light" | "dark" | "system") => void;
  currentTheme: "light" | "dark" | "system";
  isNewChatRoute: boolean;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  anchorEl,
  onClose,
  onLogout,
  onSettings,
  onFeedback,
  onPrivacyPolicy,
  onTermsOfService,
  onTechnicalSupport,
  onSalesSupport,
  onHelpCenter,
  onThemeChange,
  currentTheme,
  isNewChatRoute,
}) => {
  const userRoles = useUserStore((state) => state.getUserRoles);
  const theme = useTheme();
  const [isThemeOpen, setIsThemeOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isLegalOpen, setIsLegalOpen] = useState(false);
  const { threadId } = useParams<{ threadId: string }>();
  const isThreadActive = !!threadId;
  
  // Move hooks to the top level to avoid conditional hook calls
  const logsCount = useLogStore((state) => state.logs.length);
  const isLogModalOpen = useLogStore((state) => state.isModalOpen);

  useEffect(() => {
    if (!anchorEl) {
      setIsThemeOpen(false);
      setIsSupportOpen(false);
      setIsLegalOpen(false);
    }
  }, [anchorEl]);

  const menuIconClass = "w-6 h-6";

  const hasSettingsAccess = ALLOWED_ROLES.some((role) =>
    userRoles().includes(role)
  );

  const handleThemeClick = () => setIsThemeOpen(!isThemeOpen);
  const handleSupportClick = () => setIsSupportOpen(!isSupportOpen);
  const handleLegalClick = () => setIsLegalOpen(!isLegalOpen);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      className="mt-2"
      slotProps={{
        paper: {
          elevation: 0,
          style: {
            backgroundColor: theme.palette.background.threadsList,
            border: `1px solid ${theme.palette.background.threadsList}`,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
          },
          className:
            "backdrop-blur-sm border rounded-lg overflow-hidden w-[280px]",
        },
      }}
    >
      <MenuItem
        onClick={handleThemeClick}
        className="flex items-center gap-3 px-4 py-3 text-sm"
        disableRipple
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3">
            <Contrast className={menuIconClass} />
            <span>Theme Mode</span>
          </div>
          <ArrowDropDownIcon
            sx={{
              color: "#8A8A8A",
              transition: "transform 0.2s ease-in-out",
              transform: isThemeOpen ? "rotate(180deg)" : "none",
              width: 20,
              height: 20,
              marginLeft: "auto",
            }}
          />
        </div>
      </MenuItem>
      <AnimatePresence mode="wait">
        {isThemeOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="overflow-hidden"
            style={{ willChange: "height" }}
          >
            <Box>
              <ThemeMenuItem
                icon={<LightModeOutlinedIcon fontSize="small" />}
                label="Light"
                onClick={() => onThemeChange("light")}
                delay={0.1}
                isSelected={currentTheme === "light"}
              />
              <ThemeMenuItem
                icon={<DarkModeOutlinedIcon fontSize="small" />}
                label="Dark"
                onClick={() => onThemeChange("dark")}
                delay={0.15}
                isSelected={currentTheme === "dark"}
              />
              <ThemeMenuItem
                icon={<SettingsBrightnessIcon fontSize="small" />}
                label="System"
                onClick={() => onThemeChange("system")}
                delay={0.2}
                isSelected={currentTheme === "system"}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      {hasSettingsAccess && (
        <MenuItem
          onClick={onSettings}
          className="flex items-center gap-3 px-4 py-3 text-sm"
        >
          <Settings className={menuIconClass} />
          <span>Settings</span>
        </MenuItem>
      )}
      {isThreadActive && (
        <MenuItem
          onClick={() => {
            const {
              openModal,
              setModalSize,
              setModalPosition,
              fetchLogs
            } = useLogStore.getState();
            
            // Load saved state or set defaults
            try {
              const savedState = localStorage.getItem('log-modal-state');
              if (savedState) {
                const parsedState = JSON.parse(savedState);
                if (parsedState.position) {
                  setModalPosition(parsedState.position);
                }
                if (parsedState.size) {
                  setModalSize({
                    width: Math.max(parsedState.size.width, 100),
                    height: Math.max(parsedState.size.height, 200)
                  });
                }
              } else {
                // Set defaults
                setModalPosition({ x: 20, y: 100 });
                setModalSize({ width: 400, height: 500 });
              }
            } catch (error) {
              console.error('Failed to parse saved state:', error);
              setModalPosition({ x: 20, y: 100 });
              setModalSize({ width: 400, height: 500 });
            }
            
            // Fetch logs if there's a thread
            if (threadId) {
              fetchLogs(threadId);
            }
            
            openModal();
            onClose();
          }}
          className="flex items-center justify-between px-4 py-3 text-sm"
          disableRipple
        >
          <div className="flex items-center gap-3">
            <Terminal className={menuIconClass} />
            <span>Tool Logs</span>
          </div>
          <div className="flex items-center" style={{marginLeft: '30px'}}>
            <Badge
              badgeContent={logsCount}
              color="primary"
              max={99}
              invisible={logsCount === 0 || isLogModalOpen}
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '0.65rem',
                  padding: '0 4px',
                  marginRight: '10px',
                }
              }}
            >
              <div style={{ width: 4 }}></div>
            </Badge>
            <Tooltip title="Refresh logs">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (threadId) {
                    useLogStore.getState().fetchLogs(threadId);
                  }
                }}
                style={{ marginLeft: '12px', display: 'flex' }}
              >
                <RefreshCw size={14} />
              </div>
            </Tooltip>
          </div>
        </MenuItem>
      )}
      <MenuItem
        onClick={handleSupportClick}
        className="flex items-center gap-3 px-4 py-3 text-sm"
      >
        <HelpCircle className={menuIconClass} />
        <span>Support</span>
        <ArrowDropDownIcon
          sx={{
            color: "#8A8A8A",
            transition: "transform 0.2s ease-in-out",
            transform: isSupportOpen ? "rotate(180deg)" : "none",
            width: 20,
            height: 20,
            marginLeft: "auto",
          }}
        />
      </MenuItem>
      <AnimatePresence mode="wait">
        {isSupportOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="overflow-hidden"
            style={{ willChange: "height" }}
          >
            <Box>
              <ThemeMenuItem
                label="Technical Support"
                onClick={onTechnicalSupport}
                delay={0.1}
              />
              <ThemeMenuItem
                label="Sales Support"
                onClick={onSalesSupport}
                delay={0.15}
              />
              <ThemeMenuItem
                label="Help Center"
                onClick={onHelpCenter}
                delay={0.2}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <Divider sx={{ my: 1, mx: 2 }} />
      <MenuItem
        onClick={onFeedback}
        disabled={isNewChatRoute}
        className="flex items-center gap-3 px-4 py-3 text-sm"
      >
        <MessageSquarePlus className={menuIconClass} />
        <span>Leave Feedback</span>
      </MenuItem>
      <MenuItem
        onClick={handleLegalClick}
        className="flex items-center gap-3 px-4 py-3 text-sm"
      >
        <ShieldCheck className={menuIconClass} />
        <span>Terms & Conditions</span>
        <ArrowDropDownIcon
          sx={{
            color: "#8A8A8A",
            transition: "transform 0.2s ease-in-out",
            transform: isLegalOpen ? "rotate(180deg)" : "none",
            width: 20,
            height: 20,
            marginLeft: "auto",
          }}
        />
      </MenuItem>
      <AnimatePresence mode="wait">
        {isLegalOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="overflow-hidden"
            style={{ willChange: "height" }}
          >
            <Box>
              <ThemeMenuItem
                label="Privacy Policy"
                onClick={onPrivacyPolicy}
                delay={0.1}
              />
              <ThemeMenuItem
                label="Terms of Service"
                onClick={onTermsOfService}
                delay={0.15}
              />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <Divider sx={{ my: 1, mx: 2 }} />
      <MenuItem
        onClick={onLogout}
        className="flex items-center gap-3 px-4 py-3 text-sm text-red-400 hover:bg-gray-100/10"
      >
        <LogOut className={menuIconClass} />
        <span>Logout</span>
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
