import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Typography,
  useTheme,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { X, RefreshCw } from 'lucide-react';
import { useLogStore } from '../../store/logStore';
import { formatDistanceToNow } from 'date-fns';
import { useParams } from 'react-router-dom';

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

interface ModalState {
  position: Position;
  size: Size;
}

// Define minimum modal dimensions - keep in sync with LogButton
const MIN_MODAL_WIDTH = 100;
const MIN_MODAL_HEIGHT = 200;

// LocalStorage key for modal state
const MODAL_STATE_KEY = 'log-modal-state';

const LogModal: React.FC = () => {
  const theme = useTheme();
  const { threadId } = useParams<{ threadId: string }>();
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [dragOffset, setDragOffset] = useState<Position>({ x: 0, y: 0 });
  const [resizeStartPosition, setResizeStartPosition] = useState<Position>({ x: 0, y: 0 });
  const [resizeStartSize, setResizeStartSize] = useState<Size>({ width: 0, height: 0 });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const previousStateRef = useRef<ModalState | null>(null);
  const initialLoadDoneRef = useRef(false);
  const currentSizeRef = useRef({ width: 0, height: 0 });
  const modalSizeRef = useRef({ width: 0, height: 0 });
  
  const {
    logs,
    isModalOpen,
    modalPosition,
    modalSize,
    closeModal,
    setModalPosition,
    setModalSize,
    currentThreadId,
    saveModalSize,
    fetchLogs
  } = useLogStore();

  // Keep size ref updated with latest modalSize
  useEffect(() => {
    modalSizeRef.current = modalSize;
  }, [modalSize]);

  // Initialize current size ref with modal size values
  useEffect(() => {
    if (isModalOpen && modalSize) {
      currentSizeRef.current = { 
        width: modalSize.width, 
        height: modalSize.height 
      };
    }
  }, [isModalOpen, modalSize]);

  // Track modal size with ResizeObserver
  useEffect(() => {
    if (!modalRef.current || !isModalOpen) return;
    
    // Skip ResizeObserver updates during manual resizing or dragging
    if (resizing || dragging) return;
    
    let animationFrameId: number | null = null;
    
    const resizeObserver = new ResizeObserver((entries) => {
      // Cancel any previous animation frame
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      
      // Use requestAnimationFrame to debounce naturally
      animationFrameId = requestAnimationFrame(() => {
        if (!modalRef.current || resizing || dragging) return;
        
        const entry = entries[0];
        if (entry && entry.contentRect) {
          // Apply minimum width/height constraints but preserve user-defined values
          // if they're explicitly set and larger than the minimums
          const width = Math.round(entry.contentRect.width);
          const height = Math.round(entry.contentRect.height);
          
          // Update current size ref with the actual dimensions but constrained by minimums
          currentSizeRef.current = { 
            width: Math.max(width, MIN_MODAL_WIDTH), 
            height: Math.max(height, MIN_MODAL_HEIGHT) 
          };
          
          // Only update state if significantly different (prevents minor pixel adjustments)
          const widthDiff = Math.abs(width - modalSizeRef.current.width);
          const heightDiff = Math.abs(height - modalSizeRef.current.height);
          
          if (widthDiff > 2 || heightDiff > 2) {
            setModalSize(currentSizeRef.current);
          }
        }
        animationFrameId = null;
      });
    });
    
    resizeObserver.observe(modalRef.current);
    
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
      resizeObserver.disconnect();
    };
  }, [isModalOpen, setModalSize, resizing, dragging]);

  // Save current modal state to ref whenever it changes
  useEffect(() => {
    if (modalPosition && modalSize) {
      // Update the ref
      previousStateRef.current = {
        position: modalPosition,
        size: modalSize
      };
    }
  }, [modalPosition, modalSize]);

  // Scroll to bottom when new logs arrive or modal is opened
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [logs, isModalOpen]);

  // Fetch logs when threadId changes
  useEffect(() => {
    if (threadId) {
      setIsLoading(true);
      fetchLogs(threadId)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [threadId, fetchLogs, setIsLoading]);

  // Handle mousemove for dragging and resizing
  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (dragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      setModalPosition({ x: newX, y: newY });
    } else if (resizing) {
      const deltaX = e.clientX - resizeStartPosition.x;
      const deltaY = e.clientY - resizeStartPosition.y;
      
      // Calculate new width and height
      let newWidth = resizeStartSize.width + deltaX;
      let newHeight = resizeStartSize.height + deltaY;
      
      // Apply minimum constraints
      newWidth = Math.max(newWidth, MIN_MODAL_WIDTH);
      newHeight = Math.max(newHeight, MIN_MODAL_HEIGHT);
      
      setModalSize({ width: newWidth, height: newHeight });
    }
  }, [dragging, dragOffset, resizing, resizeStartPosition, resizeStartSize, setModalPosition, setModalSize]);

  // Handle mouseup to end dragging or resizing
  const handleMouseUp = useCallback(() => {
    // Save modal state to localStorage when done dragging/resizing
    if (resizing) {
      // Use store function to update just the size in localStorage
      saveModalSize();
    }
    
    setDragging(false);
    setResizing(false);
  }, [saveModalSize, resizing, setDragging, setResizing]);

  // Add and remove event listeners for dragging and resizing
  useEffect(() => {
    if (dragging || resizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, resizing, handleMouseMove, handleMouseUp]);

  // Start dragging
  const handleHeaderMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    setDragOffset({
      x: e.clientX - modalPosition.x,
      y: e.clientY - modalPosition.y,
    });
  };

  // Start resizing
  const handleResizeMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setResizing(true);
    setResizeStartPosition({
      x: e.clientX,
      y: e.clientY,
    });
    setResizeStartSize({
      width: modalSize.width,
      height: modalSize.height,
    });
  };

  // Handle modal close with state saving
  const handleCloseModal = () => {
    // Get current dimensions
    const currentWidth = currentSizeRef.current.width;
    const currentHeight = currentSizeRef.current.height;
    
    if (currentWidth > 0 && currentHeight > 0) {
      // Ensure the store has the current size
      setModalSize({
        width: currentWidth,
        height: currentHeight
      });
    }
    
    // Use the store function to save both position and size
    closeModal();
  };

  // Refresh logs
  const handleRefreshLogs = async (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!currentThreadId || isRefreshing) return;
    
    setIsRefreshing(true);
    setIsLoading(true);
    console.log("Refreshing logs for thread:", currentThreadId);
    
    try {
      const response = await fetchLogs(currentThreadId);
      console.log("Logs refreshed successfully:", response);
    } catch (error) {
      console.error("Error refreshing logs:", error);
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  };

  if (!isModalOpen) return null;

  return (
    <Paper
      elevation={3}
      ref={modalRef}
      sx={{
        position: 'fixed',
        top: `${modalPosition.y}px`,
        left: `${modalPosition.x}px`,
        width: `${modalSize.width}px`,
        height: `${modalSize.height}px`,
        minWidth: `${MIN_MODAL_WIDTH}px`,
        minHeight: `${MIN_MODAL_HEIGHT}px`,
        zIndex: 9999,
        borderRadius: '6px',
        overflow: 'hidden',
        bgcolor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        resize: 'both',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          bgcolor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.05)',
          padding: '8px 12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'move',
          borderBottom: `1px solid ${theme.palette.divider}`,
          userSelect: 'none',
        }}
        onMouseDown={handleHeaderMouseDown}
      >
        <Typography variant="subtitle2" fontWeight="medium">
          AI Tool Logs {currentThreadId ? `- Thread: ${currentThreadId}` : ''}
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title="Refresh logs">
            <IconButton
              size="small"
              onClick={handleRefreshLogs}
              disabled={isRefreshing}
              sx={{ 
                marginRight: '4px',
                animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }}
            >
              <RefreshCw size={14} />
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={handleCloseModal}
            sx={{ padding: '4px' }}
          >
            <X size={16} />
          </IconButton>
        </Box>
      </Box>

      {/* Content */}
      <Box
        ref={contentRef}
        sx={{
          padding: '8px 12px',
          height: 'calc(100% - 72px)', // Header height (40px) + footer height (32px)
          overflow: 'auto',
          fontSize: '0.85rem',
          fontFamily: 'monospace',
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={32} />
          </Box>
        ) : logs.length === 0 ? (
          <Typography color="text.secondary" fontSize="0.85rem" align="center" sx={{ marginTop: '20px' }}>
            No logs available
          </Typography>
        ) : (
          logs.map((log) => (
            <Box
              key={log.id}
              sx={{
                marginBottom: '8px',
                padding: '8px',
                borderRadius: '4px',
                background: log.status === 'error' 
                  ? theme.palette.mode === 'dark' 
                    ? 'rgba(244, 67, 54, 0.1)' 
                    : 'rgba(244, 67, 54, 0.05)'
                  : theme.palette.mode === 'dark'
                    ? 'rgba(30, 30, 30, 0.5)'
                    : 'rgba(0, 0, 0, 0.03)',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={0.5}>
                <Typography 
                  fontWeight="bold" 
                  color={log.status === 'error' ? 'error' : 'text.primary'} 
                  fontSize="0.85rem"
                >
                  {log.toolName}
                </Typography>
                <Typography color="text.secondary" fontSize="0.75rem">
                  {log.timestamp ? formatDistanceToNow(new Date(log.timestamp), { addSuffix: true }) : 'Unknown time'}
                </Typography>
              </Box>
              
              <Typography fontSize="0.75rem" mb={0.5}>
                Status: <span style={{ color: log.status === 'error' ? theme.palette.error.main : theme.palette.success.main }}>
                  {log.status}
                </span> 
                {log.executionTime ? ` (${log.executionTime}ms)` : ''}
              </Typography>
              
              <Box mt={1} sx={{ fontSize: '0.75rem' }}>
                {Object.keys(log.parameters).length > 0 && (
                  <>
                    <Typography fontSize="0.75rem" fontWeight="bold">Parameters:</Typography>
                    <Box 
                      sx={{ 
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        height: '200px',
                        overflow: 'auto',
                        marginTop: '4px',
                        marginBottom: '8px',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        resize: 'vertical',
                        minHeight: '100px',
                        position: 'relative',
                        '&:hover': {
                          boxShadow: '0 0 0 1px ' + theme.palette.divider,
                        },
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '2px',
                          right: '2px',
                          width: '10px',
                          height: '10px',
                          background: `linear-gradient(
                            135deg,
                            transparent 0%,
                            transparent 50%,
                            ${theme.palette.text.secondary} 50%,
                            ${theme.palette.text.secondary} 100%
                          )`,
                          opacity: 0.5,
                          pointerEvents: 'none'
                        }
                      }}
                    >
                      {JSON.stringify(log.parameters, null, 2)}
                    </Box>
                  </>
                )}
                
                {Object.keys(log.response).length > 0 && (
                  <>
                    <Typography fontSize="0.75rem" fontWeight="bold">Response:</Typography>
                    <Box 
                      sx={{ 
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        height: '300px',
                        overflow: 'auto',
                        marginTop: '4px',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        resize: 'vertical',
                        minHeight: '100px',
                        position: 'relative',
                        '&:hover': {
                          boxShadow: '0 0 0 1px ' + theme.palette.divider,
                        },
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '2px',
                          right: '2px',
                          width: '10px',
                          height: '10px',
                          background: `linear-gradient(
                            135deg,
                            transparent 0%,
                            transparent 50%,
                            ${theme.palette.text.secondary} 50%,
                            ${theme.palette.text.secondary} 100%
                          )`,
                          opacity: 0.5,
                          pointerEvents: 'none'
                        }
                      }}
                    >
                      {JSON.stringify(log.response, null, 2)}
                    </Box>
                  </>
                )}
                
                {log.error && (
                  <>
                    <Typography fontSize="0.75rem" fontWeight="bold" color="error" mt={1}>Error:</Typography>
                    <Box 
                      sx={{ 
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(244, 67, 54, 0.1)' : 'rgba(244, 67, 54, 0.05)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        height: '100px',
                        overflow: 'auto',
                        marginTop: '4px',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                        color: theme.palette.error.main,
                        resize: 'vertical',
                        minHeight: '50px',
                        position: 'relative',
                        '&:hover': {
                          boxShadow: '0 0 0 1px ' + theme.palette.error.main,
                        },
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '2px',
                          right: '2px',
                          width: '10px',
                          height: '10px',
                          background: `linear-gradient(
                            135deg,
                            transparent 0%,
                            transparent 50%,
                            ${theme.palette.error.main} 50%,
                            ${theme.palette.error.main} 100%
                          )`,
                          opacity: 0.5,
                          pointerEvents: 'none'
                        }
                      }}
                    >
                      {log.error}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))
        )}
      </Box>

      {/* Footer/resize handle */}
      <Box
        sx={{
          height: '12px',
          cursor: 'nwse-resize',
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '12px',
        }}
        onMouseDown={handleResizeMouseDown}
      />
    </Paper>
  );
};

export default LogModal; 