import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Outlet } from "react-router-dom";
import { AnimatedLogo } from "../components/AnimatedLogo/AnimatedLogo";
import TokenRefresher from "../components/TokenRefresher/TokenRefresher";
import { useTokenRefresh } from "../hooks/useTokenRefresh";
import Footer from "./components/Footer";
import Header from "./components/Header";

const MainLayout: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { isAuthenticated: _isAuthenticated } = useAuth0();

  // Initialize token refresh mechanism
  useTokenRefresh();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
        color: "text.primary",
        transition: theme.transitions.create(["background-color", "color"], {
          duration: theme.transitions.duration.standard,
        }),
        overflow: "hidden",
      }}
    >
      <TokenRefresher />

      <Header>
        <AnimatedLogo
          className="text-xl sm:text-2xl"
          showHeader={!isMobile && !isMedium}
          fontSize={isMobile ? "24px" : isMedium ? "26px" : undefined}
        />
      </Header>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.main,
          transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.standard,
          }),
          marginTop: theme.spacing(8),
          marginBottom: theme.spacing(1),
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderRadius: { xs: 0, sm: 2 },
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </motion.div>

      <Footer />
    </Box>
  );
};

export { MainLayout };
