import { grey } from "@mui/material/colors";
import { COLORS, hexToRgba } from "./colors";

export const lightPalette = {
  primary: {
    main: "#D9D9DA",
  },
  secondary: {
    main: "#B5B4B7",
  },
  background: {
    default: grey[50],
    paper: grey[100],
    dark: COLORS.softBlack,
    main: COLORS.white,
    footer: COLORS.bgGray,
    input: hexToRgba(COLORS.bgGray, 0.3),
    icon: COLORS.softBlack,
    topicBorder: COLORS.bgGray,
    assistant: COLORS.assistantBG,
    messageAsistant: hexToRgba(COLORS.bgGray, 0.3),
    messageUser: COLORS.softGray,
    threadsList: COLORS.white,
    threadBox: hexToRgba(COLORS.bgGray, 0.3),
    searchIcon: COLORS.lightGray,
    menuCheckIcon: COLORS.assistantBG,
    menuSubItem: hexToRgba(COLORS.bgGray, 0.4),
  },
  text: {
    primary: grey[900],
    secondary: grey[800],
    white: COLORS.white,
    contrastWhite: COLORS.white,
    contrastDisable: hexToRgba(COLORS.lightGray, 0.23),
    contrastEnable: COLORS.softBlack,
    tip: COLORS.darkGray,
    mainText: COLORS.darkGray,
    headerMainText: COLORS.softBlack,
    topicBoxText: COLORS.darkGray,
    messageAsistant: COLORS.softBlack,
    messageUser: COLORS.softBlack,
    noThreadsText: COLORS.darkGray,
    headerButton: COLORS.white,
    footerText: COLORS.mediumGray,
  },
};
