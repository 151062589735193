import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Rating,
  Box,
  Typography,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { threadsApiService } from "../../services/api/threads";
import { z } from "zod";

interface FeedbackFormProps {
  threadId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: FeedbackData) => void;
}

export interface FeedbackData {
  threadId: string;
  title: string;
  description: string;
  rating: number;
}

const feedbackSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required").min(5, "Description must be at least 5 characters long"),
  rating: z.number().min(1, "Please select a rating"),
});

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  threadId,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState<number | null>(0);
  const [hover, setHover] = useState(-1);
  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
    rating?: string;
  }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const feedbackData = {
      threadId,
      title,
      description,
      rating: rating || 0,
    };

    const validation = feedbackSchema.safeParse(feedbackData);
    if (!validation.success) {
      const formattedErrors = validation.error.format();
      setErrors({
        title: formattedErrors.title?._errors[0],
        description: formattedErrors.description?._errors[0],
        rating: formattedErrors.rating?._errors[0],
      });
      return;
    }

    // Clear any previous errors
    setErrors({});
    setIsSubmitting(true);

    try {
      await threadsApiService.sendThreadFeedback({
        threadId,
        feedback: feedbackData,
      });
      onSubmit(feedbackData);
      setTitle("");
      setDescription("");
      setRating(0);
      setShowSuccessAlert(true);
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error("Failed to send feedback", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    if (errors.title) {
      setErrors({ ...errors, title: undefined });
    }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    if (errors.description) {
      setErrors({ ...errors, description: undefined });
    }
  };

  const handleRatingChange = (_: React.SyntheticEvent, newValue: number | null) => {
    setRating(newValue);
    if (errors.rating) {
      setErrors({ ...errors, rating: undefined });
    }
  };

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Thread Feedback</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
              <TextField
                label="Title"
                value={title}
                onChange={handleTitleChange}
                fullWidth
                size="medium"
                error={!!errors.title}
                required
              />
              {errors.title && (
                <FormHelperText error>{errors.title}</FormHelperText>
              )}
              <TextField
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
                multiline
                rows={4}
                fullWidth
                size="medium"
                error={!!errors.description}
                required
              />
              {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
              <Box sx={{ mt: 1 }}>
                <Typography component="legend">Rating*</Typography>
                <Rating
                  value={rating}
                  onChange={handleRatingChange}
                  onChangeActive={(_, newHover) => setHover(newHover)}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                  size="large"
                />
                {rating !== null && (
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {hover !== -1 ? hover : rating} out of 5
                  </Typography>
                )}
                {errors.rating && (
                  <FormHelperText error>{errors.rating}</FormHelperText>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="inherit" disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Feedback"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={2000}
        onClose={handleCloseSuccessAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
          Feedback submitted successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedbackForm;
