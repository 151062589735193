import { BaseApiService } from "./base";
import { ApiResponse, ThreadResponse, ThreadsResponse } from "../../types";
import { useUserStore } from "../../store/userStore";
import { SendFeedbackRequest } from "../../types/thread.types";

export class ThreadsApiService extends BaseApiService {
  constructor() {
    super();

    this.api.interceptors.request.use((config) => {
      const token = useUserStore.getState().jwtToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.withCredentials = true;
      }
      return config;
    });
  }

  async getThreads(): Promise<ApiResponse<ThreadsResponse>> {
    return this.api.get<unknown, ApiResponse<ThreadsResponse>>("/threads");
  }

  async deleteThread(threadId: string): Promise<ApiResponse<void>> {
    return this.api.delete<unknown, ApiResponse<void>>(`/threads/${threadId}`);
  }

  async getThreadById(threadId: string): Promise<ApiResponse<ThreadResponse>> {
    return this.api.get<unknown, ApiResponse<ThreadResponse>>(
      `/threads/${threadId}`
    );
  }

  async sendThreadFeedback(
    request: SendFeedbackRequest
  ): Promise<ApiResponse<void>> {
    return this.api.post<unknown, ApiResponse<void>>(
      `/threads/${request.threadId}/feedback`,
      request.feedback,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export const threadsApiService = new ThreadsApiService();
