// src/theme/theme.ts
import { PaletteMode } from "@mui/material";
import { Components, createTheme, ThemeOptions } from "@mui/material/styles";
import { hexToRgba } from "./colors";
import { darkPalette } from "./darkPalette";
import { lightPalette } from "./lightPalette";

declare module "@mui/material/styles" {
  interface TypeBackground {
    dark: string;
    main: string;
    footer: string;
    input: string;
    icon: string;
    topicBorder: string;
    assistant: string;
    messageAsistant: string;
    messageUser: string;
    threadsList: string;
    threadBox: string;
    searchIcon: string;
    menuCheckIcon: string;
    menuSubItem: string;
  }

  interface TypeText {
    white: string;
    contrastWhite: string;
    contrastDisable: string;
    contrastEnable: string;
    tip: string;
    mainText: string;
    headerMainText: string;
    topicBoxText: string;
    messageAsistant: string;
    messageUser: string;
    noThreadsText: string;
    headerButton: string;
    footerText: string;
  }
}

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light" ? lightPalette : darkPalette),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          scrollbarWidth: "thin",
          scrollbarColor: `${
            mode === "light" ? hexToRgba("#475569", 0.3) : "#C4C4C4"
          } transparent`,
        },
      },
    },
  },
});

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      "Barlow Semi Condensed",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.75rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.25rem",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
    },
    button: {
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  components: {
    MuiBox: {
      defaultProps: {
        sx: {
          bgcolor: "transparent",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
      defaultProps: {
        sx: {
          textTransform: "none",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          bgcolor: "rgba(255, 255, 255, 0.15)",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.15)",
          },
          "&::after": {
            background:
              "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [
            {
              fontFamily: "Barlow Semi Condensed",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
            },
          ],
        },
      },
    },
  } as Components,
};

export const theme = createTheme(themeOptions);
export { themeOptions };
