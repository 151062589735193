// types/user.ts

// Enums
export type StorageType = "s3" | "file" | "cloud" | "mongo";
export type AIModel = "gpt-4o-mini" | "gpt-4";

// Settings interface
export interface UserSettings {
  accessToken: string | null;
  storage: StorageType;
  model: AIModel;
}

// Base user properties interface
export interface UserProps {
  _id?: string;
  id?: string; // Added to support both formats
  email: string;
  settings?: UserSettings;
  roles?: string[];
  groups?: string[];
  createdAt: string;
  updatedAt?: string;
}

// Response interfaces
export interface SignInResponse {
  user?: UserResponse;
  data?: UserProps; // Added to support the response format from the backend
  jwtToken: string;
  error: null | {
    message: string;
    code: string;
    status: number;
  };
}

export interface UserResponse {
  _id?: string;
  id?: string; // Added to support both formats
  email: string;
  settings?: UserSettings;
  roles?: string[];
  groups?: string[];
  createdAt: string;
  updatedAt?: string;
}

export interface SignInRequest {
  email: string;
  accessToken: string;
  silent?: boolean;
}

// User class
export class User {
  _id: string;
  email: string;
  settings: UserSettings;
  createdAt: Date;
  updatedAt: Date;
  roles: string[];
  groups: string[];

  constructor(props: UserProps) {
    this._id = props._id || props.id || "";
    this.email = props.email;
    this.settings = props.settings || { accessToken: null, storage: "s3", model: "gpt-4o-mini" };
    this.roles = props.roles || ["user"];
    this.groups = props.groups || [];
    this.createdAt = new Date(props.createdAt);
    this.updatedAt = props.updatedAt ? new Date(props.updatedAt) : new Date();
  }

  static createFrom(data: UserProps): User {
    return new User(data);
  }

  deleteAccessToken(): void {
    this.settings.accessToken = null;
    this.updatedAt = new Date();
  }

  toJSON(): UserProps {
    return {
      _id: this._id,
      email: this.email,
      settings: this.settings,
      roles: this.roles,
      groups: this.groups,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
  }
}