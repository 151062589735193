import { useAuth0 } from "@auth0/auth0-react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Toolbar,
  useTheme,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { motion } from "framer-motion";
import { MessageCircle, Plus } from "lucide-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import MobileMenu from "../../components/MobileMenu/MobileMenu";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import ThreadsList from "../../components/ThreadsList/ThreadsList";
import { apiServices } from "../../services/api";
import { useThemeContext } from "../../services/contexts/ThemeContext";
import { getUserBGColor } from "../../services/utils/getUserBGColor";
import { getUserInitials } from "../../services/utils/getUserInitials";
import { useMessageStore } from "../../store/messageStore";
import { useThreadStore } from "../../store/threadStore";
import { useUserStore } from "../../store/userStore";

interface HeaderProps {
  children?: React.ReactNode;
}

export const bgColor = getUserBGColor();

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNewChatRoute = location.pathname === "/app/chat/new";

  // Auth related states and handlers
  const logout = useUserStore((state) => state.logout);
  const { logout: auth0Logout, user: auth0User } = useAuth0();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      handleCloseProfile();

      await apiServices.user.signOut();
      logout();
      await auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });

      setTimeout(() => {
        navigate("/login", { replace: true });
        setIsLoggingOut(false);
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      navigate("/login", { replace: true });
      setIsLoggingOut(false);
    }
  };

  // Menu states and handlers
  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<HTMLElement | null>(null);
  const [chatAnchorEl, setChatAnchorEl] = React.useState<HTMLElement | null>(
    null
  );
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) =>
    setProfileAnchorEl(event.currentTarget);
  const handleCloseProfile = () => {
    setProfileAnchorEl(null);
  };
  const handleChatMenu = (event: React.MouseEvent<HTMLElement>) => {
    setChatAnchorEl(event.currentTarget);
    if (chatAnchorEl) handleCloseChat();
  };
  const handleCloseChat = () => setChatAnchorEl(null);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  // Theme related states and handlers
  const { setMode, mode } = useThemeContext();

  const handleThemeChange = (newMode: "light" | "dark" | "system") => {
    setMode(newMode);
    handleCloseProfile();
    localStorage.setItem("theme", newMode);
  };

  // Feedback related states and handlers
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedbackThreadId, setFeedbackThreadId] = useState<string>("");

  const handleFeedback = () => {
    handleCloseProfile();
    const currentThread = useThreadStore.getState().currentThread;
    if (currentThread) {
      setFeedbackThreadId(currentThread._id);
      setIsFeedbackOpen(true);
    } else {
      console.error("No current thread available for feedback");
    }
  };

  const handleFeedbackSubmit = () => {
    try {
      setIsFeedbackOpen(false);
      toast.success("Feedback submitted successfully");
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // Navigation and routing handlers
  const handleLogoClick = () => navigate("/app/chat/new");

  const handleSettings = () => {
    handleCloseProfile();
    navigate("/app/settings");
    setMobileOpen(false);
  };

  // Threads related handlers
  const { setCurrentThread } = useThreadStore();
  const { clearMessages } = useMessageStore();

  const handleNewChat = () => {
    if (isNewChatRoute) {
      handleCloseChat();
      return;
    }
    handleCloseChat();
    handleCloseProfile();
    setCurrentThread(null);
    setMobileOpen(false);
    navigate("/app/chat/new");
    clearMessages();
    setTimeout(() => {
      document.querySelector<HTMLTextAreaElement>(".chat-input")?.focus();
      toast.info("Send your first message to start a new thread", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }, 100);
  };

  const handleTechnicalSupport = () => {
    window.open(
      "mailto:tech-support@cprime.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleSalesSupport = () => {
    window.open(
      "mailto:sales-support@cprime.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleHelpCenter = () => {
    window.open(
      "https://www.cprime.com/support",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handlePrivacyPolicy = () => {
    navigate("/app/privacy");
    handleCloseProfile();
  };

  const handleTermsOfService = () => {
    navigate("/app/terms");
    handleCloseProfile();
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
        style={{
          backgroundColor: theme.palette.background.dark,
        }}
        elevation={1}
      >
        <Toolbar className="flex justify-between items-center bg-inherit">
          <div className="flex items-center gap-4">
            <div
              className="flex items-center cursor-pointer"
              onClick={handleLogoClick}
            >
              {children}
            </div>
            {!isNewChatRoute && (
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3, delay: 1.5 }}
              >
                <Button
                  onClick={handleNewChat}
                  disabled={isNewChatRoute}
                  key="new-thread-button"
                  sx={{
                    border: `1px solid ${theme.palette.text.contrastWhite}`,
                    color: theme.palette.text.contrastWhite,
                    paddingRight: "12px",
                    borderRadius: "6px",
                    fontFamily: "Barlow",
                    [theme.breakpoints.down("sm")]: {
                      width: "123px",
                      height: "36px",
                      padding: "5px 12px 7px 12px",
                      borderRadius: "6px",
                      borderWidth: "1px",
                    },
                  }}
                >
                  <Plus size={16} className="mr-2 text-coral" />
                  New Thread
                </Button>
              </motion.div>
            )}
          </div>

          {isMobile ? (
            <div className="flex items-center">
              <IconButton onClick={handleChatMenu}>
                <MessageCircle
                  size={20}
                  color={theme.palette.text.contrastWhite}
                />
              </IconButton>
              <IconButton onClick={handleDrawerToggle} className="ml-2">
                <MenuIcon sx={{ color: theme.palette.text.contrastWhite }} />
              </IconButton>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Button
                onClick={handleChatMenu}
                sx={{
                  fontFamily: "Barlow",
                  color: theme.palette.text.contrastWhite,
                  fontSize: "0.875rem",
                  fontWeight: 500,
                }}
                startIcon={<MessageCircle size={20} />}
              >
                Threads
              </Button>

              <IconButton
                data-testid="profile-menu-button"
                onClick={handleProfileMenu}
                color="inherit"
                edge="end"
                aria-label="profile menu"
                disableRipple
                sx={{
                  backgroundColor: "transperent",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <div className="flex flex-row items-center">
                  {auth0User ? (
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: bgColor,
                        fontSize: "16px",
                        color: "#FFFFFF",
                        marginRight: "8px",
                      }}
                    >
                      {getUserInitials(auth0User)}
                    </Avatar>
                  ) : (
                    <Skeleton
                      variant="circular"
                      width={32}
                      height={32}
                      animation="wave"
                      sx={{
                        marginRight: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        "&::after": {
                          background:
                            "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)",
                        },
                      }}
                    />
                  )}
                  {auth0User ? (
                    <div className="text-left font-main text-sm w-[180px] font-Barlow">
                      <p
                        className="text-white truncate max-w-[180px] leading-tight "
                        title={auth0User.name}
                      >
                        {auth0User.name}
                      </p>
                      <p
                        className="text-neuturalsLightGrey truncate max-w-[180px] leading-tight -mt-0.5 text-xs"
                        title={auth0User.email}
                      >
                        {auth0User.email}
                      </p>
                    </div>
                  ) : (
                    <div className="text-left font-main text-sm w-[180px]">
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={16}
                        animation="wave"
                        sx={{
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                          "&::after": {
                            background:
                              "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)",
                          },
                        }}
                      />
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={14}
                        animation="wave"
                        sx={{
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                          "&::after": {
                            background:
                              "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent)",
                          },
                        }}
                      />
                    </div>
                  )}
                  <div className="w-5 h-5 flex items-center">
                    {auth0User && (
                      <ArrowDropDownIcon
                        sx={{
                          color: "#E6E6E6",
                          transition: "transform 0.2s ease-in-out",
                          transform: profileAnchorEl
                            ? "rotate(180deg)"
                            : "none",
                          width: 20,
                          height: 20,
                        }}
                      />
                    )}
                  </div>
                </div>
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <ThreadsList
        anchorEl={chatAnchorEl}
        onClose={handleCloseChat}
        onNewChat={handleNewChat}
      />

      <Backdrop
        open={Boolean(profileAnchorEl)}
        onClick={handleCloseProfile}
        className="bg-gray-900/50 backdrop-blur-sm z-[1200]"
      />

      <ProfileMenu
        anchorEl={profileAnchorEl}
        onClose={handleCloseProfile}
        onLogout={handleLogout}
        onSettings={handleSettings}
        onFeedback={handleFeedback}
        onPrivacyPolicy={handlePrivacyPolicy}
        onTermsOfService={handleTermsOfService}
        onTechnicalSupport={handleTechnicalSupport}
        onSalesSupport={handleSalesSupport}
        onHelpCenter={handleHelpCenter}
        onThemeChange={handleThemeChange}
        currentTheme={mode}
        isNewChatRoute={isNewChatRoute}
      />

      <MobileMenu
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onNewChat={handleNewChat}
        onSettings={handleSettings}
        onLogout={handleLogout}
        userName={auth0User?.name}
        userEmail={auth0User?.email}
      />

      <Dialog
        open={isLoggingOut}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Logging Out</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CircularProgress size={24} />
            <DialogContentText id="logout-dialog-description">
              Please wait while we log you out...
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>

      <FeedbackForm
        threadId={feedbackThreadId}
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
    </>
  );
};

export default Header;
