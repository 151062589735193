// src/services/api/user.ts
import { BaseApiService } from "./base";
import {
  SignInRequest,
  ApiResponse,
  User,
  UserResponse,
  SignInResponse,
} from "../../types";
import Cookies from "js-cookie";

export class UserApiService extends BaseApiService {
  /**
   * Sign in user with email and access token
   */
  async signIn(request: SignInRequest): Promise<ApiResponse<SignInResponse>> {
    try {
      const response = await this.api.post<SignInRequest, ApiResponse<SignInResponse>>(
        "/sign-in",
        request
      );

      if (response.data?.jwtToken) {
        // Set the auth token both as cookie and header
        this.setAuthToken(response.data.jwtToken);
      }

      return response;
    } catch (error) {
      // For silent token refresh, we don't want to show errors
      if (request.silent) {
        console.error('Silent sign-in failed:', error);
        return {
          data: null as unknown as SignInResponse,
          error: {
            message: 'Silent sign-in failed',
            code: 'SILENT_SIGNIN_FAILED',
            status: 401
          }
        };
      }
      
      // For regular sign-in, let the error propagate
      throw error;
    }
  }

  /**
   * Sign out current user
   */
  async signOut(): Promise<void> {
    await this.api.post("/sign-out");
    this.signOutCleanup();
  }

  private signOutCleanup(): void {
    // Remove auth token from API service
    this.removeAuthToken();

    // Clear all application storage
    localStorage.clear();
    sessionStorage.clear();

    // Clear cookies using js-cookie library
    const cookies = Object.keys(Cookies.get());
    cookies.forEach((cookie) => {
      Cookies.remove(cookie, { path: "/" });
    });
  }

  /**
   * Get current user's settings
   */
  async getSettings(): Promise<ApiResponse<UserResponse>> {
    return this.api.get<unknown, ApiResponse<UserResponse>>("/settings");
  }

  /**
   * Update user settings
   */
  async updateSettings(
    settings: Partial<User["settings"]>
  ): Promise<ApiResponse<UserResponse>> {
    return this.api.put<unknown, ApiResponse<UserResponse>>("/settings", settings);
  }
}

// Create a singleton instance
export const userApiService = new UserApiService();
