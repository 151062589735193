import { grey } from "@mui/material/colors";
import { COLORS, hexToRgba } from "./colors";

export const darkPalette = {
  primary: {
    main: "#323137",
  },
  secondary: {
    main: "#3F3E44",
  },
  background: {
    default: COLORS.strongDark,
    paper: COLORS.midDark,
    dark: COLORS.softDark,
    main: COLORS.midDark,
    footer: COLORS.midDark,
    input: COLORS.strongDark,
    icon: COLORS.bgGray,
    topicBorder: COLORS.darkGray,
    assistant: COLORS.assistantBG,
    messageAsistant: COLORS.softBlack,
    messageUser: COLORS.softDark,
    threadsList: COLORS.softDark,
    threadBox: COLORS.midDark,
    searchIcon: hexToRgba(COLORS.darkGray, 0.5),
    menuCheckIcon: COLORS.lightCoral,
    menuSubItem: hexToRgba(COLORS.darkGray, 0.2),
  },
  text: {
    primary: COLORS.white,
    secondary: grey[500],
    white: COLORS.white,
    contrastWhite: COLORS.bgGray,
    contrastDisable: COLORS.darkGray,
    contrastEnable: COLORS.mediumGray,
    tip: COLORS.lightGray,
    mainText: COLORS.bgGray,
    headerMainText: COLORS.bgGray,
    topicBoxText: COLORS.lightGray,
    messageAsistant: COLORS.white,
    messageUser: COLORS.white,
    noThreadsText: COLORS.mediumGray,
    headerButton: COLORS.bgGray,
    footerText: COLORS.darkGray,
  },
};
