import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTokenRefresh } from '../../hooks/useTokenRefresh';
import { getCurrentToken, isTokenExpired } from '../../utils/tokenUtils';

/**
 * Component that handles token refreshing globally
 * This component doesn't render anything, it just sets up the token refresh mechanism
 */
export const TokenRefresher: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { refreshToken } = useTokenRefresh();

  useEffect(() => {
    if (!isAuthenticated || isLoading) return;

    // Check if token is expired or about to expire
    const token = getCurrentToken();
    if (token && isTokenExpired(token, 120)) { // Check if token expires in the next 2 minutes
      // Refresh token silently
      refreshToken();
    }

    // Set up an interval to check token expiration every minute
    const intervalId = setInterval(() => {
      const currentToken = getCurrentToken();
      if (currentToken && isTokenExpired(currentToken, 120)) {
        refreshToken();
      }
    }, 60 * 1000); // Check every minute

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated, isLoading, refreshToken]);

  // This component doesn't render anything
  return null;
};

export default TokenRefresher; 