import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { LogEntry } from "../services/api/logging";
import { apiServices as _apiServices } from "../services/api";
import { apiServices } from "../services/api";
import { MessageResponse, Message } from "../types/message.types";

// Define minimum modal dimensions - keep in sync with LogButton and LogModal
const MIN_MODAL_WIDTH = 100;
const MIN_MODAL_HEIGHT = 200;

// LocalStorage key for modal state
const MODAL_STATE_KEY = 'log-modal-state';

interface LogState {
  logs: LogEntry[];
  currentThreadId: string | null;
  isModalOpen: boolean;
  modalPosition: { x: number; y: number };
  modalSize: { width: number; height: number };
  error: string | null;
}

interface LogActions {
  setLogs: (logs: LogEntry[]) => void;
  addLog: (log: LogEntry) => void;
  clearLogs: () => void;
  setCurrentThreadId: (threadId: string | null) => void;
  openModal: () => void;
  closeModal: () => void;
  setModalPosition: (position: { x: number; y: number }) => void;
  setModalSize: (size: { width: number; height: number }) => void;
  setError: (error: string | null) => void;
  fetchLogs: (threadId: string) => Promise<MessageResponse | undefined>;
  saveModalState: () => void;
  saveModalSize: () => void;
}

type LogStore = LogState & LogActions;

// Default modal position and size
const DEFAULT_MODAL_POSITION = { x: 20, y: 100 };
const DEFAULT_MODAL_SIZE = { width: MIN_MODAL_WIDTH, height: MIN_MODAL_HEIGHT };

// Load position and size from localStorage if available
const loadSavedState = () => {
  try {
    const savedState = localStorage.getItem(MODAL_STATE_KEY);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      
      if (parsedState.position && parsedState.size) {
        return {
          position: parsedState.position,
          size: {
            // Always respect user's saved dimensions, but enforce minimum constraints
            width: Math.max(parsedState.size.width, MIN_MODAL_WIDTH),
            height: Math.max(parsedState.size.height, MIN_MODAL_HEIGHT)
          }
        };
      }
    }
  } catch {
    // Error handling for failed state load
  }
  
  return {
    position: DEFAULT_MODAL_POSITION,
    size: DEFAULT_MODAL_SIZE
  };
};

// Get initial state
const initialState = loadSavedState();

// Direct conversion function for API response
const convertApiResponseToLogEntries = (response: MessageResponse): LogEntry[] => {
  // Extract messages from the response structure
  let messages: Message[] = [];
  
  if (response?.data) {
    if (Array.isArray(response.data)) {
      // Handle array format
      messages = response.data;
    } else if (response.data.messages && Array.isArray(response.data.messages)) {
      // Handle object format with messages array
      messages = response.data.messages;
    }
  }
  
  if (!messages || messages.length === 0) {
    return [];
  }
  
  const results: LogEntry[] = [];
  
  // Process all messages
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i] as Message & { 
      functionCall?: { 
        name: string; 
        arguments: string 
      } 
    };
    
    // Process messages with function calls, function role, or system role
    if (message.functionCall || message.role === 'function' || message.role === 'system') {
      const toolName = message.functionCall?.name || message.role;
      
      // For function calls, look for the corresponding response
      let responseMessage = null;
      if (message.functionCall) {
        responseMessage = (i + 1 < messages.length && 
                          (messages[i + 1] as Message & { role: string; name?: string }).role === 'function' && 
                          (messages[i + 1] as Message & { name?: string }).name === toolName) 
                          ? messages[i + 1] as Message & { content: string; name?: string }
                          : null;
      }
      
      // Parse the function call arguments if available
      let parameters: Record<string, unknown> = {};
      if (message.functionCall) {
        try {
          parameters = JSON.parse(message.functionCall.arguments);
        } catch {
          parameters = { raw: message.functionCall.arguments };
        }
      } else {
        // For non-function-call messages, use the content as parameters
        parameters = { content: message.content };
      }
      
      // Parse the function response if available
      let responseData: Record<string, unknown> = {};
      let status: "success" | "error" = "success";
      let errorMessage = null;
      
      if (responseMessage) {
        try {
          if (responseMessage.content) {
            const parsedContent = JSON.parse(responseMessage.content);
            responseData = parsedContent;
            
            // Check for error in the response
            if (parsedContent && typeof parsedContent === 'object') {
              if ('error' in parsedContent && parsedContent.error && parsedContent.error !== 'null') {
                status = 'error';
                errorMessage = String(parsedContent.error);
              }
            }
          }
        } catch {
          responseData = { raw: responseMessage.content || '' };
        }
        
        // Skip the response message in the next iteration
        i++;
      } else if (message.role === 'function') {
        // For function role messages, use their content as response data
        try {
          responseData = JSON.parse(message.content);
        } catch {
          responseData = { raw: message.content };
        }
      }
      
      // Create the log entry
      const logEntry: LogEntry = {
        id: message._id,
        timestamp: new Date().toISOString(),
        threadId: message._id.split('_')[0] || '',
        toolName,
        parameters,
        response: responseData,
        status,
        executionTime: 0,
        error: errorMessage
      };
      
      results.push(logEntry);
    }
  }
  
  return results;
};

export const useLogStore = create<LogStore>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        logs: [],
        currentThreadId: null,
        isModalOpen: false,
        modalPosition: initialState.position,
        modalSize: initialState.size,
        error: null,

        // Actions
        setLogs: (logs: LogEntry[]) => set({ logs }),
        
        addLog: (log: LogEntry) => set((state) => ({
          logs: [...state.logs, log]
        })),
        
        clearLogs: () => set({ logs: [] }),
        
        setCurrentThreadId: (threadId: string | null) => set({ 
          currentThreadId: threadId,
          logs: [] // Clear logs when changing threads
        }),
        
        openModal: () => set({ isModalOpen: true }),
        
        closeModal: () => {
          // Save state to localStorage before closing
          get().saveModalState();
          set({ isModalOpen: false });
        },
        
        setModalPosition: (position: { x: number; y: number }) => {
          set({ modalPosition: position });
        },
        
        setModalSize: (size: { width: number; height: number }) => {
          set({ modalSize: size });
        },
        
        saveModalState: () => {
          const { modalPosition, modalSize } = get();
          
          // Save both position and size to localStorage
          try {
            const stateToSave = {
              position: { ...modalPosition },
              size: { 
                width: modalSize.width,
                height: modalSize.height
              }
            };
            
            localStorage.setItem(MODAL_STATE_KEY, JSON.stringify(stateToSave));
          } catch {
            // Error handling for failed state save
          }
        },
        
        saveModalSize: () => {
          const { modalSize } = get();
          
          // Only update the size in localStorage, preserve the existing position
          try {
            const currentStateStr = localStorage.getItem(MODAL_STATE_KEY);
            const currentState = currentStateStr ? JSON.parse(currentStateStr) : {};
            
            const stateToSave = {
              ...currentState,
              size: { 
                width: modalSize.width,
                height: modalSize.height
              }
            };
            
            localStorage.setItem(MODAL_STATE_KEY, JSON.stringify(stateToSave));
          } catch {
            // Error handling for failed size save
          }
        },
        
        setError: (error: string | null) => set({ error }),
        
        // Fetch logs from the API
        fetchLogs: async (threadId: string) => {
          try {
            const { setLogs, setError, setCurrentThreadId } = get();
            
            // Update current thread ID
            setCurrentThreadId(threadId);
            
            // Use the API service to fetch logs with functionData=true
            const response = await apiServices.message.getMessagesByThreadId(threadId, { 
              functionData: true,
              // The timestamp will be ignored by the API but helps prevent browser caching
              limit: Math.floor(Math.random() * 1000) + 100
            });
            
            if (response) {
              // Convert the API response to log entries
              const convertedLogs = convertApiResponseToLogEntries(response);
              console.log(`Fetched ${convertedLogs.length} logs for thread ${threadId}`);
              setLogs(convertedLogs);
              setError(null);
            } else {
              console.log(`No logs found for thread ${threadId}`);
              setLogs([]);
            }
            
            return response;
          } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to fetch logs';
            console.error(`Error fetching logs: ${errorMessage}`);
            get().setError(errorMessage);
            throw error;
          }
        },
      }),
      {
        name: "log-storage",
        partialize: (state) => ({
          // Only persist these fields
          modalPosition: state.modalPosition,
          modalSize: state.modalSize,
        }),
      }
    )
  )
); 