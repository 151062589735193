// src/utils/errorHandling.ts
import { toast } from "react-toastify";

export interface ErrorDetails {
  code: string;
  message: string;
  status?: number;
  field?: string;
}

export class ApplicationError extends Error {
  code: string;
  status?: number;
  field?: string;

  constructor(details: ErrorDetails) {
    super(details.message);
    this.code = details.code;
    this.status = details.status;
    this.field = details.field;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = (error: any): ErrorDetails => {
  // If it's already our ApplicationError, use it directly
  if (error instanceof ApplicationError) {
    return {
      code: error.code,
      message: error.message,
      status: error.status,
      field: error.field,
    };
  }

  // Handle Axios errors
  if (error.isAxiosError) {
    const status = error.response?.status;
    const apiError = error.response?.data;

    // Network error (no response received)
    if (!error.response) {
      return {
        code: "NETWORK_ERROR",
        message:
          "Unable to connect to the server. Please check your internet connection.",
        status: 0,
      };
    }

    // Handle specific HTTP status codes
    switch (status) {
      case 401:
        return {
          code: "UNAUTHORIZED",
          message: "You are not authorized to perform this action.",
          status: 401,
        };
      case 403:
        return {
          code: "FORBIDDEN",
          message: "You are not authorized to perform this action.",
          status: 403,
        };
      case 404:
        return {
          code: "RESOURCE_NOT_FOUND",
          message: "The requested resource was not found.",
          status: 404,
        };
      case 429:
        return {
          code: "RATE_LIMIT_EXCEEDED",
          message: "Too many requests. Please try again later.",
          status: 429,
        };
      case 500:
        return {
          code: "SERVER_ERROR",
          message:
            "An unexpected server error occurred. Please try again later.",
          status: 500,
        };
    }

    // Use API provided error if available
    if (apiError) {
      return {
        code: apiError.code || "API_ERROR",
        message: apiError.message,
        status: status,
        field: apiError.field,
      };
    }
  }

  // Handle file-related errors
  if (error instanceof File || error.name === "File") {
    if (error.size > 10 * 1024 * 1024) {
      // 10MB limit example
      return {
        code: "FILE_TOO_LARGE",
        message: "The file size exceeds the maximum limit.",
      };
    }
  }

  // Default unknown error
  return {
    code: "UNKNOWN_ERROR",
    message: error.message || "An unexpected error occurred. Please try again.",
    status: 500,
  };
};

// Utility function to show error toast
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showErrorToast = (error: any) => {
  const errorDetails = handleError(error);
  
  // Don't show toast messages for 401/400 errors
  if (errorDetails.status === 401 || errorDetails.status === 400) {
    // Just log the error to console
    console.error(`Auth error (${errorDetails.status}): ${errorDetails.message}`);
    return errorDetails;
  }
  
  // Show toast for all other errors
  toast.error(errorDetails.message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  
  return errorDetails;
};

// Utility function for form field errors
export const getFieldError = (
  error: unknown,
  fieldName: string
): string | undefined => {
  const errorDetails = handleError(error);
  if (errorDetails.field === fieldName) {
    return errorDetails.message;
  }
  return undefined;
};
