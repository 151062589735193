import { BaseApiService } from "./base";
import { ApiResponse } from "../../types";

interface ReconcileRequest {
  sha: string;
}

export class DocumentApiService extends BaseApiService {
  /**
   * Reconcile documents with a specific Git commit
   */
  async reconcileDocuments(request: ReconcileRequest): Promise<ApiResponse<void>> {
    return this.api.post<ReconcileRequest, ApiResponse<void>>("/reconcile", request);
  }
}

// Create a singleton instance
export const documentApiService = new DocumentApiService();
