import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { User, UserProps } from "../types/user.types";
import { AuthState, LoadingState } from "../types/state";

interface UserStore extends AuthState {
  jwtToken: string | null;
  setUser: (userData: UserProps) => void;
  setJwtToken: (token: string) => void;
  setLoading: (state: LoadingState) => void;
  setError: (error: string | null) => void;
  logout: () => void;
  getUserRoles: () => string[];
}

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        user: null,
        jwtToken: null,
        isAuthenticated: false,
        loading: "idle",
        error: null,

        // Actions
        setUser: (userData: UserProps) =>
          set({
            user: User.createFrom(userData),
            isAuthenticated: true,
            loading: "success",
            error: null,
          }),

        setJwtToken: (token: string) => set({ jwtToken: token }),

        setLoading: (state: LoadingState) => set({ loading: state }),

        getUserRoles: () => get().user?.roles || [],

        setError: (error: string | null) =>
          set({
            error: error
              ? { message: error, code: "AUTH_ERROR", status: 401 }
              : null,
            loading: error ? "error" : "idle",
          }),

        logout: () =>
          set({
            user: null,
            jwtToken: null,
            isAuthenticated: false,
            loading: "idle",
            error: null,
          }),
      }),
      {
        name: "user-storage",
        // Only persist these fields
        partialize: (state) => ({
          user: state.user,
          jwtToken: state.jwtToken,
          isAuthenticated: state.isAuthenticated,
        }),
      }
    )
  )
);
