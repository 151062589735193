import { useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiServices } from '../services/api';
import { useUserStore } from '../store/userStore';
import { getTokenExpirationTime, getCurrentToken } from '../utils/tokenUtils';
import { UserProps } from '../types';

// Token will be refreshed 2 minutes before it expires
const REFRESH_BUFFER_TIME = 120 * 1000; // 2 minutes in milliseconds
// Default token expiration time (4 minutes) if we can't determine it
const DEFAULT_TOKEN_EXPIRATION = 4 * 60 * 1000; // 4 minutes in milliseconds

/**
 * Custom hook to handle JWT token refreshing
 * This hook will set up a timer to refresh the token before it expires
 */
export const useTokenRefresh = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const setJwtToken = useUserStore((state) => state.setJwtToken);
  const setUser = useUserStore((state) => state.setUser);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Define function types to avoid circular dependency
  type RefreshTokenFn = () => Promise<boolean>;
  type ScheduleTokenRefreshFn = (token: string) => void;

  // Use refs to break the circular dependency
  const refreshTokenRef = useRef<RefreshTokenFn | null>(null);
  const scheduleTokenRefreshRef = useRef<ScheduleTokenRefreshFn | null>(null);

  // Function to schedule the next token refresh
  const scheduleTokenRefresh = useCallback((token: string) => {
    // Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    
    // Get token expiration time
    const expirationTime = getTokenExpirationTime(token) || (Date.now() + DEFAULT_TOKEN_EXPIRATION);
    
    // Calculate when to refresh (expiration time minus buffer)
    const refreshTime = expirationTime - Date.now() - REFRESH_BUFFER_TIME;
    
    // Schedule refresh if the token is not already expired
    if (refreshTime > 0) {
      timerRef.current = setTimeout(() => {
        if (refreshTokenRef.current) {
          refreshTokenRef.current();
        }
      }, refreshTime);
    } else {
      // If token is already expired or about to expire, refresh immediately
      if (refreshTokenRef.current) {
        refreshTokenRef.current();
      }
    }
  }, []);

  // Store the scheduleTokenRefresh function in a ref
  scheduleTokenRefreshRef.current = scheduleTokenRefresh;

  // Function to refresh the token silently without showing errors
  const refreshToken = useCallback(async (): Promise<boolean> => {
    if (!isAuthenticated) return false;
    
    try {
      // Get a new access token from Auth0
      const accessToken = await getAccessTokenSilently({ 
        cacheMode: 'off',
        detailedResponse: false,
        timeoutInSeconds: 10
      });
      
      // Get user email from store
      const userEmail = useUserStore.getState().user?.email;
      
      if (!userEmail) {
        return false;
      }
      
      // Call backend to refresh JWT
      const response = await apiServices.user.signIn({
        email: userEmail,
        accessToken,
        silent: true // Add silent flag to indicate this is a silent refresh
      });
      
      if (response.data?.jwtToken) {
        // Update the token and user in the store
        setJwtToken(response.data.jwtToken);
        
        // Check for user data in either user or data property
        const userData = response.data.user || response.data.data;
        if (userData && userData.email) {
          setUser(userData as UserProps);
        }
        
        // Set up the next refresh
        if (scheduleTokenRefreshRef.current) {
          scheduleTokenRefreshRef.current(response.data.jwtToken);
        }
        return true;
      }
      
      return false;
    } catch {
      // Silently fail - log error but don't show to user
      
      // Try again in 30 seconds if there was an error
      timerRef.current = setTimeout(() => {
        if (refreshTokenRef.current) {
          refreshTokenRef.current();
        }
      }, 30 * 1000);
      return false;
    }
  }, [isAuthenticated, getAccessTokenSilently, setJwtToken, setUser]);

  // Store the refreshToken function in a ref
  refreshTokenRef.current = refreshToken;

  useEffect(() => {
    // Initialize token refresh when the component mounts
    if (isAuthenticated) {
      const token = getCurrentToken();
      if (token) {
        scheduleTokenRefresh(token);
      } else {
        // If no token is found, try to refresh immediately
        refreshToken();
      }
    }
    
    // Clean up timer on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isAuthenticated, refreshToken, scheduleTokenRefresh]);

  return { refreshToken };
}; 