import React from "react";
import { Navigate } from "react-router-dom";
import { useUserStore } from "../../store/userStore";

interface SettingsRouteProps {
  children: React.ReactNode;
}

export const ALLOWED_ROLES = ["admin"];

const SettingsRoute: React.FC<SettingsRouteProps> = ({ children }) => {
  const userRoles = useUserStore((state) => state.getUserRoles);
  const hasSettingsAccess = ALLOWED_ROLES.some((role) =>
    userRoles().includes(role)
  );

  if (!hasSettingsAccess) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default SettingsRoute;
