import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { messageApiService, eventSourceApiService } from "../services/api";
import { ApiError } from "../types";
import { Message, MessageResponse } from "../types/message.types";

interface MessageState {
  messages: MessageResponse[];
  currentMessage: MessageResponse | null;
  loading: "idle" | "loading" | "success" | "error";
  error: ApiError | null;
  sseConnection: EventSource | null;
  threadMessages: MessageResponse | null;
  responseContent: string;
  newThreadId: string | null;
}

interface MessageActions {
  sendMessage: (message: string, threadId?: string) => Promise<MessageResponse>;
  setCurrentMessage: (message: MessageResponse | null) => void;
  setLoading: (state: MessageState["loading"]) => void;
  setError: (error: ApiError | null) => void;
  clearMessages: () => void;
  readAloud: (message: string) => void;
  getMessagesByThreadId: (threadId: string) => Promise<MessageResponse>;
  rateMessage: (messageId: string, rating: number) => Promise<void>;
  callStreamAgent: (
    message: string,
    threadId?: string,
    files?: File[]
  ) => Promise<void>;

  // Updates response content
  setResponseContent: (content: string) => void;

  setNewThreadId: (threadId: string | null) => void;
}

interface MessageStore extends MessageState, MessageActions {}

export const useMessageStore = create<MessageStore>()(
  devtools(
    persist(
      (set, _get) => ({
        // Initial state
        messages: [],
        currentMessage: null,
        loading: "idle",
        error: null,
        sseConnection: null,
        threadMessages: null,
        responseContent: "",
        newThreadId: null,

        // Sends a normal message
        sendMessage: async (message: string, threadId?: string) => {
          try {
            set({ loading: "loading", error: null });
            const response = await messageApiService.sendMessage({
              message,
              threadId,
            });
            const filteredMessages = (response.data as Message[]).filter(
              (msg: { role: string }) => msg.role !== "system"
            );
            const messageResponse: MessageResponse = {
              data: filteredMessages,
              error: response.error,
            };

            set((state) => ({
              messages: [...state.messages, messageResponse],
              currentMessage: messageResponse,
              loading: "success",
            }));

            return messageResponse;
          } catch (error) {
            const apiError = error as ApiError;
            set({ error: apiError, loading: "error" });
            throw error;
          }
        },

        callStreamAgent: async (
          message: string,
          threadId?: string,
          files?: File[]
        ) => {
          try {
            set({
              loading: "loading",
              error: null,
              responseContent: "",
              newThreadId: null,
            });

            const response =
              await eventSourceApiService.startStreamingAgentSession(
                message,
                threadId,
                files
              );

            const sessionId = response.data;

            await eventSourceApiService.invokeAgent(sessionId, {
              onMessage: (event: MessageEvent) => {
                if (event.type === "message") {
                  try {
                    const data = event.data;
                    // console.log("Received chunk:", data);
                    set((state) => ({
                      responseContent: state.responseContent + data,
                      // Keep loading state while receiving chunks
                      loading: "loading",
                    }));
                  } catch (e) {
                    console.error("Error processing message:", e);
                  }
                }
              },
              onComplete: () => {
                // Set success state only when stream is complete
                set({
                  loading: "success",
                });
              },
              onError: (error: Event) => {
                const source = error.target as EventSource;
                if (source?.readyState !== EventSource.CLOSED) {
                  set({
                    error: {
                      message: "Connection error",
                      code: "SSE_ERROR",
                      status: 500,
                    },
                    loading: "error",
                  });
                }
              },
              onOpen: () => {
                set({ loading: "loading" });
              },
            });
          } catch (error) {
            console.error("Stream agent error:", error);
            set({
              error: {
                message:
                  error instanceof Error ? error.message : "Stream agent error",
                code: "SSE_ERROR",
                status: 500,
              },
              loading: "error",
            });
            throw error;
          }
        },

        setNewThreadId: (threadId: string | null) =>
          set({ newThreadId: threadId }),

        setResponseContent: (content: string) =>
          set({ responseContent: content }),

        getMessagesByThreadId: async (threadId: string) => {
          try {
            set({ loading: "loading", error: null });
            const response = await messageApiService.getMessagesByThreadId(
              threadId,
              { functionData: false }
            );
            const messages = Array.isArray(response.data) ? response.data : [];
            const filteredMessages = messages.filter(
              (msg: { role: string }) => msg.role !== "system"
            );
            const messageResponse: MessageResponse = {
              data: filteredMessages,
              error: null,
            };
            
            // If we only have a user message but expect an AI response, 
            // wait briefly and retry once
            if (filteredMessages.length === 1 && 
                filteredMessages[0].role === "user") {
              
              // Wait a bit for the server to finish processing
              await new Promise(resolve => setTimeout(resolve, 800));
              
              // Retry the fetch
              try {
                const retryResponse = await messageApiService.getMessagesByThreadId(
                  threadId
                );
                const retryMessages = Array.isArray(retryResponse.data) 
                  ? retryResponse.data 
                  : [];
                const retryFiltered = retryMessages.filter(
                  (msg: { role: string }) => msg.role !== "system"
                );
                
                // Only use retry results if they have more messages
                if (retryFiltered.length > filteredMessages.length) {
                  const retryMessageResponse: MessageResponse = {
                    data: retryFiltered,
                    error: null,
                  };
                  set({ threadMessages: retryMessageResponse, loading: "success" });
                  return retryMessageResponse;
                }
              } catch (retryError) {
                console.warn("Retry fetch failed, using original results:", retryError);
              }
            }
            
            set({ threadMessages: messageResponse, loading: "success" });
            return messageResponse;
          } catch (error) {
            console.error("Error fetching thread messages:", error);
            const apiError = error as ApiError;
            set({ error: apiError, loading: "error" });
            throw error;
          }
        },

        rateMessage: async (messageId: string | undefined, rating: number) => {
          if (!messageId) {
            throw new Error("Message ID is required");
          }
          try {
            set({ loading: "loading", error: null });
            await messageApiService.rateMessage(messageId, rating);
            set({ loading: "success" });
          } catch (error) {
            const apiError = error as ApiError;
            set({ error: apiError, loading: "error" });
            throw error;
          }
        },

        setCurrentMessage: (message: MessageResponse | null) =>
          set({ currentMessage: message }),
        setLoading: (state: MessageState["loading"]) => set({ loading: state }),
        setError: (error: ApiError | null) =>
          set({ error, loading: error ? "error" : "idle" }),
        clearMessages: () =>
          set({
            messages: [],
            currentMessage: null,
            loading: "idle",
            error: null,
            sseConnection: null,
            threadMessages: null,
            responseContent: "",
            newThreadId: null,
          }),
        readAloud: (message: string) => {
          const utterance = new SpeechSynthesisUtterance(message);
          speechSynthesis.speak(utterance);
        },
      }),
      {
        name: "message-storage",
        partialize: (state) => ({
          messages: state.messages,
          currentMessage: state.currentMessage,
          threadMessages: state.threadMessages,
          responseContent: state.responseContent,
          newThreadId: state.newThreadId,
        }),
      }
    )
  )
);
