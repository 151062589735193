// eslint-disable-next-line
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvVar } from '../../utils/validateEnv';
import { PropsWithChildren } from 'react';

export const Auth0ProviderWithConfig = ({ children }: PropsWithChildren) => {
  const domain = getEnvVar('REACT_APP_AUTH0_DOMAIN');
  const clientId = getEnvVar('REACT_APP_AUTH0_CLIENT_ID');

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email'
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
};