export const getUserBGColor = () => {
  const colors = [
    "#2E7D32",
    "#2E467D",
    "#9B3A3C",
    "#7D2E7D",
    "#7D692E",
    "#396B7F",
    "#804A39",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
