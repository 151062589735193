// src/services/api/base.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosError,
} from "axios";
import { getEnvVar } from "../../utils/validateEnv";
import Cookies from "js-cookie";
import { useUserStore } from "../../store/userStore";
import {
  handleError,
  showErrorToast,
  ApplicationError,
} from "../../utils/errorHandling";
import { SignInResponse, ApiResponse, UserProps } from "../../types";
// Import Auth0Client type only for type checking
import { Auth0Client as _Auth0Client } from "@auth0/auth0-spa-js";

// Add a variable to track token refresh attempts
let isRefreshingToken = false;
let refreshTokenPromise: Promise<ApiResponse<SignInResponse>> | null = null;
const MAX_REFRESH_ATTEMPTS = 3;
let refreshAttempts = 0;

export class BaseApiService {
  protected api: AxiosInstance;

  constructor() {
    const apiUrl = getEnvVar("REACT_APP_API_URL");
    const apiVersion = getEnvVar("REACT_APP_API_VERSION");

    this.api = axios.create({
      baseURL: `${apiUrl}/${apiVersion}`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      timeout: 300000, // 5 minutes timeout
      timeoutErrorMessage:
        "Request timed out after 5 minutes. Please try breaking your question into smaller parts.",
    });

    // Add request interceptor
    this.api.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = Cookies.get("jwt");
        if (token && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        // Handle request errors (e.g., no internet connection)
        const errorDetails = handleError(error);
        showErrorToast({
          code: errorDetails.code,
          message: errorDetails.message,
        });
        return Promise.reject(new ApplicationError(errorDetails));
      }
    );

    // Add response interceptor
    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        const jwtToken = response.headers["set-cookie"]?.find(
          (cookie: string) => cookie.startsWith("jwt=")
        );
        if (jwtToken) {
          const tokenValue = jwtToken.split(";")[0].split("=")[1];
          Cookies.set("jwt", tokenValue, {
            path: "/",
            secure: true,
            sameSite: "strict",
          });
        }
        
        // Reset refresh attempts on successful response
        refreshAttempts = 0;
        
        return response.data;
      },
      async (error) => {
        const errorDetails = handleError(error);

        // Handle 401 Unauthorized errors
        if (error.response?.status === 401 || error.response?.status === 400) {
          const isTokenExpired =
            error.response?.data?.error === "TokenExpired" ||
            error.response?.data?.message
              ?.toLowerCase()
              .includes("refresh your token");

          if (isTokenExpired) {
            // Check if we've exceeded max refresh attempts
            if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
              // Don't show error, just silently try to refresh Auth0 token
              await this.silentAuth0Refresh();
              
              // Retry the original request
              return this.api.request(error.config);
            }
            
            refreshAttempts++;
            
            // If we're already refreshing the token, wait for that to complete
            if (isRefreshingToken) {
              if (refreshTokenPromise) {
                try {
                  await refreshTokenPromise;
                  // Retry the original request
                  return this.api.request(error.config);
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catch (_) {
                  // If refresh fails, try Auth0 silent refresh
                  await this.silentAuth0Refresh();
                  return this.api.request(error.config);
                }
              }
            }
            
            // Start a new token refresh
            isRefreshingToken = true;
            refreshTokenPromise = this.refreshToken()
              .then((response) => {
                isRefreshingToken = false;
                return response;
              })
              .catch(async (refreshError) => {
                isRefreshingToken = false;
                refreshTokenPromise = null;
                
                // If backend refresh fails, try Auth0 silent refresh
                await this.silentAuth0Refresh();
                throw refreshError;
              });
            
            try {
              await refreshTokenPromise;
              // Retry the original request
              return this.api.request(error.config);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            catch (_) {
              // If all refresh attempts fail, retry the request anyway
              // It might still fail, but we don't want to redirect or show errors
              return this.api.request(error.config);
            }
          }
          
          // For all other 401/400 errors, don't show toast messages
          // Just return a rejected promise with the error
          return Promise.reject(new ApplicationError(errorDetails));
        }

        // Handle 500 Server errors
        if (error.response?.status >= 500) {
          showErrorToast({
            code: "SERVER_ERROR",
            message: "A server error occurred. Please try again later.",
          });
          return Promise.reject(
            new ApplicationError({
              code: "SERVER_ERROR",
              message: "A server error occurred. Please try again later.",
              status: error.response.status,
            })
          );
        }

        if (error.code === "ECONNABORTED") {
          showErrorToast({
            code: "TIMEOUT",
            message: "Request timed out. Please try again.",
          });
          return Promise.reject(
            new ApplicationError({
              code: "TIMEOUT",
              message: "Request timed out. Please try again.",
            })
          );
        }

        // Show error toast for all other errors (except 401/400)
        if (error.response?.status !== 401 && error.response?.status !== 400) {
          showErrorToast({
            code: errorDetails.code,
            message: errorDetails.message,
          });
        }

        // Return a rejected promise with the formatted error
        return Promise.reject(new ApplicationError(errorDetails));
      }
    );
  }

  // Silently refresh Auth0 token and then backend token
  private async silentAuth0Refresh(): Promise<void> {
    try {
      // Dynamically import the getAuth0Client function
      const { getAuth0Client } = await import("../../utils/auth0Utils");
      
      // Get Auth0 client
      const auth0 = await getAuth0Client();
      if (!auth0) return;
      
      // Check if user is authenticated
      const isAuthenticated = await auth0.isAuthenticated();
      if (!isAuthenticated) return;
      
      // Get user info
      const user = await auth0.getUser();
      if (!user || !user.email) return;
      
      // Get a fresh access token silently
      const accessToken = await auth0.getTokenSilently({ cacheMode: 'off' });
      
      // Call backend to refresh JWT
      const response = await this.api.post<unknown, ApiResponse<SignInResponse>>("/sign-in", {
        email: user.email,
        accessToken: accessToken,
        silent: true // Add silent flag to indicate this is a silent refresh
      });
      
      if (response.data?.jwtToken) {
        this.setAuthToken(response.data.jwtToken);
        
        // Update user store
        const userStore = useUserStore.getState();
        const userData = response.data.user || response.data.data;
        if (userData && userData.email) {
          userStore.setUser(userData as UserProps);
        }
      }
    } catch (error) {
      // Silently fail - don't show any errors to the user
      console.error("Silent Auth0 refresh failed:", error);
    }
  }

  async refreshToken(): Promise<ApiResponse<SignInResponse>> {
    // Get user email from the store
    const userStore = useUserStore.getState();
    const userEmail = userStore.user?.email;

    if (!userEmail) {
      console.error('User email not found in store during token refresh');
      throw new ApplicationError({
        message: 'User email not found in store',
        code: 'USER_EMAIL_NOT_FOUND',
        status: 401
      });
    }

    const response = await this.api.post<unknown, ApiResponse<SignInResponse>>("/refresh-jwt", { email: userEmail });
    
    if (response.data?.jwtToken) {
      this.setAuthToken(response.data.jwtToken);
      
      // If the response contains user data, update the user store
      const userData = response.data.user || response.data.data;
      if (userData && userData.email) {
        userStore.setUser(userData as UserProps);
      }
    }
    
    return response;
  }

  private handleSessionExpired() {
    // Instead of redirecting, try to silently refresh the token
    this.silentAuth0Refresh().catch(() => {
      // Only if silent refresh completely fails, then we handle session expiry
      
      // Clear auth state
      this.removeAuthToken();
  
      // Update user store
      const userStore = useUserStore.getState();
      userStore.logout();
      
      // Only redirect to login if we're not already on a public route
      const publicRoutes = ["/login", "/reset-password", "/500"];
      const isPublicRoute = publicRoutes.some(route => window.location.pathname.startsWith(route));
      
      if (!isPublicRoute) {
        // Store current path for redirect after login
        const currentPath = window.location.pathname;
        sessionStorage.setItem("redirectAfterLogin", currentPath);
        
        // Redirect to login page
        window.location.href = "/login";
      }
    });
  }

  protected setAuthToken(token: string) {
    Cookies.set("jwt", token, {
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    useUserStore.getState().setJwtToken(token);
  }

  protected removeAuthToken() {
    Cookies.remove("jwt");
    delete this.api.defaults.headers.common["Authorization"];
    const userStore = useUserStore.getState();
    userStore.logout();
  }

  // Utility method for handling file uploads
  protected async handleFileUpload(
    file: File,
    endpoint: string,
    additionalData?: Record<string, string | number | boolean>
  ) {
    try {
      // Validate file size (10MB limit)
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
      if (file.size > MAX_FILE_SIZE) {
        throw new ApplicationError({
          code: "FILE_TOO_LARGE",
          message: "File size exceeds 10MB limit",
        });
      }

      // Create form data
      const formData = new FormData();
      formData.append("file", file);

      if (additionalData) {
        Object.entries(additionalData).forEach(([key, value]) => {
          formData.append(key, value.toString());
        });
      }

      // Make the request
      return await this.api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      const errorDetails = handleError(error);
      
      // Don't show toast for 401/400 errors
      const axiosError = error as AxiosError;
      if (axiosError.response?.status !== 401 && axiosError.response?.status !== 400) {
        showErrorToast({
          code: errorDetails.code,
          message: errorDetails.message,
        });
      }
      
      throw new ApplicationError(errorDetails);
    }
  }
}
