import { BaseApiService } from "./base";
import { ApiResponse } from "../../types";
import { EventSourceCallbacks } from "../../types/event-source-service.types";
import { getEnvVar } from "../../utils/validateEnv";
import { ApplicationError } from "../../utils/errorHandling";

export class EventSourceApiService extends BaseApiService {
  private eventSource: EventSource | null = null;
  private messageCallback: ((data: string) => void) | null = null;
  private errorCallback: ((error: Event) => void) | null = null;
  private completeCallback: (() => void) | null = null;
  private isClosing = false;
  private messageHandler: ((event: MessageEvent) => void) | null = null;

  constructor() {
    super();
  }

  connect(url: string): Promise<EventSource> {
    return new Promise((resolve, reject) => {
      try {
        this.eventSource = new EventSource(url, { withCredentials: true });

        this.eventSource.onopen = () => {
          resolve(this.eventSource!);
        };

        this.messageHandler = (event: MessageEvent) => {
          if (this.messageCallback && !this.isClosing) {
            try {
              const messageData = JSON.parse(event.data);
              this.messageCallback(messageData.content);
            } catch (e) { // eslint-disable-line @typescript-eslint/no-unused-vars
              this.messageCallback(event.data);
            }
          }
        };

        this.eventSource.addEventListener("message", this.messageHandler);

        this.eventSource.addEventListener("complete", () => {
          this.isClosing = true;
          if (this.completeCallback) {
            this.completeCallback();
          }
          this.close();
        });

        this.eventSource.onerror = (error: Event) => {
          const source = error.target as EventSource;

          if (this.isClosing) {
            return;
          }

          if (source?.readyState === EventSource.CLOSED && !this.isClosing) {
            console.error("SSE connection error:", error);
            if (this.errorCallback) {
              this.errorCallback(error);
            }
            reject(
              new ApplicationError({
                code: "SSE_CONNECTION_ERROR",
                message: "Server-Sent Events connection error",
                status: 500,
              })
            );
          }
        };
      } catch (error) { // eslint-disable-line @typescript-eslint/no-unused-vars
        reject(
          new ApplicationError({
            code: "SSE_INITIALIZATION_ERROR",
            message: "Failed to initialize Server-Sent Events connection",
            status: 500,
          })
        );
      }
    });
  }

  onMessage(callback: (data: string) => void): void {
    this.messageCallback = callback;
  }

  onComplete(callback: () => void): void {
    this.completeCallback = callback;
  }

  onError(callback: (error: Event) => void): void {
    this.errorCallback = callback;
  }

  close(): void {
    this.isClosing = true;
    if (this.eventSource) {
      this.eventSource.onopen = null;
      this.eventSource.onerror = null;
      if (this.messageHandler) {
        this.eventSource.removeEventListener("message", this.messageHandler);
      }
      this.eventSource.removeEventListener("complete", () => {});
      this.eventSource.close();
      this.eventSource = null;
      this.messageHandler = null;
    }
  }

  static getFullUrl(path: string): string {
    const apiUrl = getEnvVar("REACT_APP_API_URL");
    const apiVersion = getEnvVar("REACT_APP_API_VERSION");
    return `${apiUrl}/${apiVersion}${path}`;
  }

  async startStreamingAgentSession(
    message: string,
    threadId?: string,
    files?: File[]
  ): Promise<ApiResponse<string>> {
    const formData = new FormData();
    const body = JSON.stringify({ message, threadId });
    formData.append("body", body);

    if (files?.length) {
      files.forEach((file) => formData.append("files", file));
    }

    try {
      const response = await this.api.post<unknown, ApiResponse<string>>(
        "/agent/session",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response.data) {
        throw new ApplicationError({
          code: "INVALID_RESPONSE",
          message: "Invalid response: missing data field",
          status: 500,
        });
      }

      return response;
    } catch (error) {
      console.error("Session creation error:", error);
      throw error;
    }
  }

  async invokeAgent(
    sessionId: string,
    callbacks: EventSourceCallbacks
  ): Promise<void> {
    const url = EventSourceApiService.getFullUrl(`/agent/${sessionId}`);
    const service = new EventSourceApiService();

    service.onMessage((data) => {
      callbacks.onMessage(new MessageEvent("message", { data }));
    });

    if (callbacks.onError) {
      service.onError(callbacks.onError);
    }

    if (callbacks.onComplete) {
      service.onComplete(callbacks.onComplete);
    }

    await service.connect(url);
  }
}

// Create a singleton instance
export const eventSourceApiService = new EventSourceApiService();
