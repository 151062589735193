import { motion } from "framer-motion";
import React from "react";

const ANIMATION_CONFIG = {
  spring: {
    type: "spring",
    stiffness: 260,
    damping: 20,
  },
  fadeIn: {
    duration: 0.1,
    ease: "easeOut",
  },
} as const;

const TEXT_CONFIG = {
  company: {
    text: "prime",
    fontSize: "30px",
    fontWeight: 700,
  },
  header: {
    text: " AI sales Assistant",
    fontSize: "24px",
    fontWeight: 400,
  },
  timing: {
    cDuration: 0.3,
    charDelay: 0.05,
  },
} as const;

interface AnimatedLogoProps {
  className?: string;
  showHeader?: boolean;
  fontSize?: string;
  textColor?: "white" | "black";
}

export const AnimatedLogo: React.FC<AnimatedLogoProps> = ({
  className = "",
  showHeader = true,
  fontSize,
  textColor = "white",
}) => {
  const {
    text: textCompany,
    fontSize: defaultCompanyFontSize,
    fontWeight: companyFontWeight,
  } = TEXT_CONFIG.company;
  const {
    text: textHeader,
    fontSize: headerFontSize,
    fontWeight: headerFontWeight,
  } = TEXT_CONFIG.header;
  const { cDuration, charDelay } = TEXT_CONFIG.timing;

  const primeDuration = textCompany.length * charDelay;
  const totalCompanyDuration = cDuration + primeDuration;

  const companyFontSize = fontSize || defaultCompanyFontSize;

  return (
    <h1
      className={`font-main text-[24px] font-medium ${
        textColor === "black" ? "text-black" : "text-white"
      } ${className}`}
    >
      <motion.span
        className="text-[#ed1854] inline-block"
        style={{
          fontSize: companyFontSize,
          fontWeight: companyFontWeight,
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={ANIMATION_CONFIG.spring}
      >
        c
      </motion.span>
      {textCompany.split("").map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            ...ANIMATION_CONFIG.fadeIn,
            delay: cDuration + index * charDelay,
          }}
          style={{
            fontSize: companyFontSize,
            fontWeight: companyFontWeight,
          }}
        >
          {char}
        </motion.span>
      ))}
      {showHeader &&
        textHeader.split("").map((char, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              ...ANIMATION_CONFIG.fadeIn,
              delay: totalCompanyDuration + index * charDelay,
            }}
            style={{
              fontSize: headerFontSize,
              fontWeight: headerFontWeight,
            }}
          >
            {char}
          </motion.span>
        ))}
    </h1>
  );
};
