import React from 'react';
import * as Sentry from '@sentry/react';

// This component serves as a wrapper for React Router to enable Sentry performance monitoring
// with proper route naming
const SentryRouterWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Update Sentry transaction with route information
  React.useEffect(() => {
    // Create a transaction name based on the current location
    const transactionName = window.location.pathname;
    
    // Set the transaction with the route name
    const transaction = Sentry.startTransaction({
      name: `route:${transactionName}`,
      op: 'navigation',
      data: {
        pathname: window.location.pathname,
      },
    });

    // Set the transaction as the current span for automatic instrumentation
    Sentry.configureScope(scope => {
      scope.setSpan(transaction);
    });

    // End the transaction when we navigate away
    return () => {
      transaction.finish();
    };
  }, []);

  return <>{children}</>;
};

export default SentryRouterWrapper; 