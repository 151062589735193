import React from "react"; // eslint-disable-line @typescript-eslint/no-unused-vars

const ThreadIcon = () => {
  return (
    <svg
      width="15"
      height="6"
      viewBox="0 0 15 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66732 5.5C1.43121 5.5 1.23329 5.42014 1.07357 5.26042C0.913845 5.10069 0.833984 4.90278 0.833984 4.66667C0.833984 4.43056 0.913845 4.23264 1.07357 4.07292C1.23329 3.91319 1.43121 3.83333 1.66732 3.83333H13.334C13.5701 3.83333 13.768 3.91319 13.9277 4.07292C14.0875 4.23264 14.1673 4.43056 14.1673 4.66667C14.1673 4.90278 14.0875 5.10069 13.9277 5.26042C13.768 5.42014 13.5701 5.5 13.334 5.5H1.66732ZM1.66732 2.16667C1.43121 2.16667 1.23329 2.08681 1.07357 1.92708C0.913845 1.76736 0.833984 1.56944 0.833984 1.33333C0.833984 1.09722 0.913845 0.899306 1.07357 0.739583C1.23329 0.579861 1.43121 0.5 1.66732 0.5H13.334C13.5701 0.5 13.768 0.579861 13.9277 0.739583C14.0875 0.899306 14.1673 1.09722 14.1673 1.33333C14.1673 1.56944 14.0875 1.76736 13.9277 1.92708C13.768 2.08681 13.5701 2.16667 13.334 2.16667H1.66732Z"
        fill="#8A8A8A"
      />
    </svg>
  );
};

export default ThreadIcon;
