import { getEnvVar as _getEnvVar } from "../../utils/validateEnv";
import { BaseApiService } from "./base";
import { messageApiService } from "./messages";
import { MessageResponse } from "../../types/message.types";

export interface LogEntry {
  id: string;
  timestamp: string;
  threadId: string;
  toolName: string;
  parameters: Record<string, unknown>;
  response: Record<string, unknown>;
  status: "success" | "error";
  executionTime: number;
  error: string | null;
}

// Message logs from HTTP API
export interface ThreadLogMessage {
  _id: string;
  role: string;
  content: string;
  name: string | null;
  functionCall: {
    name: string;
    arguments: string;
  } | null;
}

export interface ThreadLogsResponse {
  data: ThreadLogMessage[];
  error: string | null;
}

// API service for HTTP-based log fetching
export class LoggingApiService extends BaseApiService {
  async getThreadLogs(threadId: string): Promise<MessageResponse> {
    // Use functionData=true parameter to fetch logs
    return messageApiService.getMessagesByThreadId(threadId, { 
      functionData: true
    });
  }
  
  // Convert API response format to match the expected LogEntry format for consistent usage
  convertToLogEntries(logs: MessageResponse): LogEntry[] {
    // Handle potentially nested data structure
    let logsData: ThreadLogMessage[] = [];
    
    if (logs && typeof logs === 'object') {
      if ('data' in logs && Array.isArray(logs.data)) {
        logsData = logs.data as unknown as ThreadLogMessage[];
      } else if (logs.data && typeof logs.data === 'object' && 'data' in logs.data && Array.isArray(logs.data.data)) {
        // Handle potentially nested structure
        logsData = logs.data.data as unknown as ThreadLogMessage[];
      } else if (logs.data && typeof logs.data === 'object' && 'messages' in logs.data && Array.isArray(logs.data.messages)) {
        // Handle the message response structure
        logsData = logs.data.messages as unknown as ThreadLogMessage[];
      }
    }
    
    if (logsData.length === 0) {
      return [];
    }
    
    // Create pairs of function calls and responses
    const pairs: { call: ThreadLogMessage, response: ThreadLogMessage | null }[] = [];
    
    // First pass: collect all calls
    for (let i = 0; i < logsData.length; i++) {
      const message = logsData[i];
      
      if (message.role === 'assistant' && message.functionCall) {
        // Check if next message is the response
        const responseMessage = i + 1 < logsData.length ? logsData[i + 1] : null;
        
        // If next message is a function response matching the call name, pair them
        if (responseMessage && 
            responseMessage.role === 'function' && 
            responseMessage.name === message.functionCall.name) {
          pairs.push({
            call: message,
            response: responseMessage
          });
          
          // Skip the response in next iteration
          i++;
        } else {
          // No matching response yet
          pairs.push({
            call: message,
            response: null
          });
        }
      }
    }
    
    // Convert pairs to LogEntry objects
    const convertedLogs: LogEntry[] = pairs.map(pair => {
      const { call, response } = pair;
      const toolName = call.functionCall!.name;
      let parameters: Record<string, unknown> = {};
      
      try {
        parameters = JSON.parse(call.functionCall!.arguments);
      } catch {
        parameters = { raw: call.functionCall!.arguments };
      }
      
      let responseData: Record<string, unknown> = {};
      let status: "success" | "error" = "success";
      let errorMessage: string | null = null;
      
      if (response) {
        try {
          responseData = JSON.parse(response.content);
          
          // Check for errors in the response
          if (typeof responseData === 'object' && responseData !== null) {
            if ('error' in responseData && responseData.error && responseData.error !== 'null') {
              status = 'error';
              errorMessage = String(responseData.error);
            }
          }
        } catch {
          responseData = { raw: response.content || '' };
        }
      }
      
      return {
        id: call._id,
        timestamp: new Date().toISOString(),
        threadId: call._id.split('_')[0] || '',
        toolName,
        parameters,
        response: responseData,
        status,
        executionTime: 0, // Not available in API response
        error: errorMessage
      };
    });
    
    return convertedLogs;
  }
  
  // Helper function to safely parse JSON
  private tryParseJson(jsonString: string): Record<string, unknown> | null {
    if (!jsonString) return null;
    
    try {
      return JSON.parse(jsonString);
    } catch {
      return null;
    }
  }
}

// Create singleton instance
export const loggingApiService = new LoggingApiService();