import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import SettingsRoute from "./components/ProtectedRoutes/SettingsRoute";
import { LogModal } from "./components/LogModal";
import { useTokenRefresh } from "./hooks/useTokenRefresh";
import { AuthLayout } from "./layouts/AuthLayout";
import { MainLayout } from "./layouts/MainLayout";
import { getCurrentToken, isTokenExpired } from "./utils/tokenUtils";

const LoginPage = React.lazy(() => import("./pages/auth/Login"));
const ResetPasswordPage = React.lazy(
  () => import("./pages/auth/ResetPassword")
);
const ChatPage = React.lazy(() => import("./pages/chat/ChatPage"));
const NewChatPage = React.lazy(() => import("./pages/chat/NewChatPage"));
const SettingsPage = React.lazy(() => import("./pages/settings/Settings"));
const TermsOfServicePage = React.lazy(
  () => import("./pages/legal/TermsOfService")
);
const PrivacyPolicyPage = React.lazy(
  () => import("./pages/legal/PrivacyPolicy")
);
const NotFoundPage = React.lazy(() => import("./pages/error/NotFound"));
const ServerErrorPage = React.lazy(() => import("./pages/error/ServerError"));

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { refreshToken } = useTokenRefresh();
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    // Check if token is expired or about to expire
    const token = getCurrentToken();
    if (token && isTokenExpired(token, 120)) {
      // Check if token expires in the next 2 minutes
      // Set refreshing state
      setIsRefreshing(true);

      // Refresh token silently
      refreshToken().finally(() => {
        setIsRefreshing(false);
      });
    }
  }, [refreshToken]);

  // Show loading screen while Auth0 is loading or we're refreshing the token
  if (isLoading || isRefreshing) {
    return <LoadingScreen />;
  }

  // Only redirect if not authenticated and not currently refreshing
  if (!isAuthenticated && !isRefreshing) {
    // Store the current path for redirect after login
    sessionStorage.setItem("redirectAfterLogin", window.location.pathname);
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

const AppWrapper = () => {
  return (
    <>
      <RouterProvider 
        router={router} 
        future={{
          v7_startTransition: true,
        }}
      />
      <LogModal />
    </>
  );
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: (
            <React.Suspense fallback={<LoadingScreen />}>
              <LoginPage />
            </React.Suspense>
          ),
        },
        {
          path: "reset-password",
          element: (
            <React.Suspense fallback={<LoadingScreen />}>
              <ResetPasswordPage />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/app",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <ProtectedRoute />,
          children: [
            {
              path: "chat",
              children: [
                {
                  path: "new",
                  element: (
                    <React.Suspense fallback={<LoadingScreen />}>
                      <NewChatPage />
                    </React.Suspense>
                  ),
                },
                {
                  path: ":threadId",
                  element: (
                    <React.Suspense fallback={<LoadingScreen />}>
                      <ChatPage />
                    </React.Suspense>
                  ),
                },
              ],
            },
            {
              path: "settings",
              element: (
                <SettingsRoute>
                  <React.Suspense fallback={<LoadingScreen />}>
                    <SettingsPage />
                  </React.Suspense>
                </SettingsRoute>
              ),
            },
            {
              path: "terms",
              element: (
                <React.Suspense fallback={<LoadingScreen />}>
                  <TermsOfServicePage />
                </React.Suspense>
              ),
            },
            {
              path: "privacy",
              element: (
                <React.Suspense fallback={<LoadingScreen />}>
                  <PrivacyPolicyPage />
                </React.Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/500",
      element: (
        <React.Suspense fallback={<LoadingScreen />}>
          <ServerErrorPage />
        </React.Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <React.Suspense fallback={<LoadingScreen />}>
          <NotFoundPage />
        </React.Suspense>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export const AppRouter = () => {
  return <AppWrapper />;
};

export default AppRouter;
