import React from "react";
import { MenuItem, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import CheckIcon from "@mui/icons-material/Check";

interface ThemeMenuItemProps {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
  delay?: number;
  isSelected?: boolean;
}

const ThemeMenuItem: React.FC<ThemeMenuItemProps> = ({
  label,
  onClick,
  icon,
  delay = 0.1,
  isSelected = false,
}) => {
  const theme = useTheme();
  return (
    <motion.div
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ delay }}
    >
      <MenuItem
        onClick={onClick}
        sx={{
          background: theme.palette.background.menuSubItem,
          paddingLeft: "32px",
          paddingRight: "16px",
          cursor: isSelected ? "default" : "pointer",
          "&.Mui-disabled": {
            opacity: 1,
            color: "inherit",
          },
          display: "flex",
          alignItems: "center",
        }}
        disabled={isSelected}
      >
        {!!icon && <div className="mr-3">{icon}</div>}
        <span className="flex-1">{label}</span>
        {isSelected && (
          <CheckIcon
            className="ml-3 text-lightCoral"
            fontSize="medium"
            style={{
              color: theme.palette.background.menuCheckIcon,
            }}
          />
        )}
      </MenuItem>
    </motion.div>
  );
};

export default ThemeMenuItem;
