import Cookies from 'js-cookie';

/**
 * Parse a JWT token and extract its payload
 * @param token JWT token string
 * @returns Decoded payload or null if invalid
 */
export const parseJwt = (token: string): Record<string, unknown> | null => {
  try {
    // JWT token consists of three parts: header, payload, and signature
    // We need the payload part which is the second part
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error parsing JWT token:', error);
    return null;
  }
};

/**
 * Check if a JWT token is expired or about to expire
 * @param token JWT token string
 * @param bufferSeconds Seconds before actual expiration to consider token as expired
 * @returns boolean indicating if token is expired or about to expire
 */
export const isTokenExpired = (token: string, bufferSeconds = 60): boolean => {
  try {
    const payload = parseJwt(token);
    if (!payload || !payload.exp) return true;
    
    // exp is in seconds, convert to milliseconds and compare with current time
    const expirationTime = (payload.exp as number) * 1000;
    const currentTime = Date.now();
    
    // Consider token expired if it's within the buffer time
    return expirationTime - currentTime < bufferSeconds * 1000;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true;
  }
};

/**
 * Get the current JWT token from cookies
 * @returns JWT token string or null if not found
 */
export const getCurrentToken = (): string | null => {
  return Cookies.get('jwt') || null;
};

/**
 * Get the expiration time of a JWT token
 * @param token JWT token string
 * @returns Expiration time in milliseconds or null if invalid
 */
export const getTokenExpirationTime = (token: string): number | null => {
  try {
    const payload = parseJwt(token);
    if (!payload || !payload.exp) return null;
    
    // exp is in seconds, convert to milliseconds
    return (payload.exp as number) * 1000;
  } catch (error) {
    console.error('Error getting token expiration time:', error);
    return null;
  }
}; 