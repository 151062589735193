// React import not needed with newer React versions
import ReactDOM from "react-dom/client";
import "./assets/styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./services/contexts/ThemeContext";
import { validateEnv } from "./utils/validateEnv";
import { initSentry } from "./sentry";
import SentryRouterWrapper from "./components/SentryRouterWrapper";

// Initialize Sentry
initSentry();

// Validate environment variables
validateEnv();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeContextProvider>
    <SentryRouterWrapper>
      <App />
    </SentryRouterWrapper>
  </ThemeContextProvider>
);

reportWebVitals();
