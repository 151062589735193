import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Badge,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Plus, LogOut, Terminal, RefreshCw } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useLogStore } from "../../store/logStore";

interface MobileMenuProps {
  open: boolean;
  onClose: () => void;
  onNewChat: () => void;
  onSettings: () => void;
  onLogout: () => void;
  userName?: string;
  userEmail?: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  open,
  onClose,
  onNewChat,
  onLogout,
  userName,
  userEmail,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { threadId } = useParams<{ threadId: string }>();
  const isThreadActive = !!threadId;

  // Move hooks to the top level to avoid conditional hook calls
  const logsCount = useLogStore((state) => state.logs.length);
  const isLogModalOpen = useLogStore((state) => state.isModalOpen);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: 250,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Box
        sx={{
          width: 250,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          ...theme.mixins.toolbar,
          pt: `${theme.mixins.toolbar.minHeight}px`,
        }}
        role="presentation"
        onClick={onClose}
      >
        <Box>
          {userName && (
            <Box
              sx={{
                p: 2,
                bgcolor: "background.paper",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Typography variant="subtitle1">{userName}</Typography>
              <Typography variant="body2" color="textSecondary">
                {userEmail}
              </Typography>
            </Box>
          )}
          <Divider />
          <List>
            <ListItem button onClick={onNewChat}>
              <ListItemIcon>
                <Plus size={20} />
              </ListItemIcon>
              <ListItemText primary="New Chat" />
            </ListItem>
            {isThreadActive && (
              <ListItem
                button
                onClick={() => {
                  const {
                    openModal,
                    setModalSize,
                    setModalPosition,
                    fetchLogs,
                  } = useLogStore.getState();

                  // Load saved state or set defaults
                  try {
                    const savedState = localStorage.getItem("log-modal-state");
                    if (savedState) {
                      const parsedState = JSON.parse(savedState);
                      if (parsedState.position) {
                        setModalPosition(parsedState.position);
                      }
                      if (parsedState.size) {
                        setModalSize({
                          width: Math.max(parsedState.size.width, 100),
                          height: Math.max(parsedState.size.height, 200),
                        });
                      }
                    } else {
                      // Set defaults
                      setModalPosition({ x: 20, y: 100 });
                      setModalSize({ width: 400, height: 500 });
                    }
                  } catch (error) {
                    console.error("Failed to parse saved state:", error);
                    setModalPosition({ x: 20, y: 100 });
                    setModalSize({ width: 400, height: 500 });
                  }

                  // Fetch logs if there's a thread
                  if (threadId) {
                    fetchLogs(threadId);
                  }

                  openModal();
                  onClose();
                }}
                disableRipple
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "16px",
                }}
                secondaryAction={
                  <div className="flex items-center">
                    <Badge
                      badgeContent={logsCount}
                      color="primary"
                      max={99}
                      invisible={logsCount === 0 || isLogModalOpen}
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: "0.65rem",
                          padding: "0 4px",
                        },
                      }}
                    >
                      <div style={{ width: 4 }}></div>
                    </Badge>
                    <Tooltip title="Refresh logs">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (threadId) {
                            useLogStore.getState().fetchLogs(threadId);
                          }
                        }}
                        sx={{ ml: 1 }}
                      >
                        <RefreshCw size={14} />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              >
                <ListItemIcon>
                  <Terminal size={20} />
                </ListItemIcon>
                <ListItemText primary="Tool Logs" />
              </ListItem>
            )}

            <ListItem button onClick={onLogout}>
              <ListItemIcon>
                <LogOut size={20} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
        <Box>
          <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                navigate("/app/privacy");
                onClose();
              }}
            >
              <ListItemText primary="Privacy Policy" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/app/terms");
                onClose();
              }}
            >
              <ListItemText primary="Terms of Service" />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileMenu;
