import * as Sentry from "@sentry/react";
import pkg from "../package.json";

// Using the DSN from environment variables with fallback to Sentry docs example
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "https://6aa57b62ab0faa7edcf162d40d8ba72c@o4509074441306112.ingest.us.sentry.io/4509074444386304";
const ENVIRONMENT = process.env.NODE_ENV || "development";
const RELEASE = `${pkg.name}@${pkg.version}`;

export const initSentry = () => {
  // Only initialize Sentry if we have a DSN (prevents initialization in local development if desired)
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: RELEASE,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
      ],
      // Adjust sample rates based on environment
      tracesSampleRate: ENVIRONMENT === "production" ? 0.2 : 1.0,
      replaysSessionSampleRate: ENVIRONMENT === "production" ? 0.1 : 0.5,
      replaysOnErrorSampleRate: 1.0,
      // Enable debug in development only
      debug: false,
    });
    
    console.log(`Sentry initialized in ${ENVIRONMENT} environment for release ${RELEASE}`);
  } else {
    console.warn("Sentry DSN not provided. Error tracking disabled.");
  }
};

// Export Sentry for direct use in components
export { Sentry }; 