// App.tsx
import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppRouter from "./router";
import { useThemeContext } from "./services/contexts/ThemeContext";
import { Suspense } from "react";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { Auth0ProviderWithConfig } from "./components/Auth0ProviderWithConfig/Auth0ProviderWithConfig";
import NetworkStatus from "./components/NetworkStatus/NetworkStatus";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";

// Define the error fallback as a function that returns a component (correct type for Sentry)
const errorFallback = ({ error, componentStack, resetError }: { 
  error: Error; 
  componentStack: string; 
  resetError: () => void;
}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '2rem',
    textAlign: 'center',
    backgroundColor: 'var(--background-color)',
    color: 'var(--text-color)',
    fontFamily: 'inherit'
  }}>
    <h2 style={{
      fontSize: '2rem',
      fontWeight: 600,
      marginBottom: '1rem',
      color: 'var(--cprime-red)'
    }}>Something went wrong</h2>
    <p style={{
      fontSize: '1.1rem',
      marginBottom: '2rem',
      color: 'var(--text-color)'
    }}>Our team has been notified of this issue.</p>
    {process.env.NODE_ENV !== 'production' && (
      <>
        <details style={{ 
          whiteSpace: 'pre-wrap',
          maxWidth: '600px',
          width: '100%',
          marginBottom: '2rem',
          textAlign: 'left',
          backgroundColor: 'rgba(227, 25, 75, 0.1)',
          padding: '1rem',
          borderRadius: '4px'
        }}>
          <summary style={{
            cursor: 'pointer',
            fontWeight: 500,
            color: 'var(--cprime-red)'
          }}>Error details (visible in development only)</summary>
          <p style={{ marginTop: '1rem' }}>{error.toString()}</p>
          <p style={{ marginTop: '0.5rem' }}>Component Stack: {componentStack}</p>
        </details>
        <button 
          onClick={resetError}
          style={{
            padding: '12px 24px',
            backgroundColor: 'var(--cprime-red)',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 500,
            transition: 'background-color 0.2s',
            fontFamily: 'inherit'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--lightCoral)'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'var(--cprime-red)'}
        >
          Try again
        </button>
      </>
    )}
  </div>
);

const App: React.FC = () => {
  const { theme } = useThemeContext();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        return entry;
      });
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Sentry.ErrorBoundary 
      fallback={errorFallback}
      beforeCapture={(scope) => {
        scope.setTag("location", "app-root");
        scope.setLevel("fatal");
      }}
    >
      <ThemeProvider theme={theme}>
        <Auth0ProviderWithConfig>
          <NetworkStatus />
          <CssBaseline />
          <Suspense fallback={<LoadingScreen />}>
            <AppRouter />
          </Suspense>
          <ToastContainer
            theme={theme.palette.mode}
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Auth0ProviderWithConfig>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
