export const hexToRgba = (hex: string, opacity: number): string => {
  const cleanHex = hex.replace("#", "");

  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const COLORS = {
  white: "#FFF",
  softBlack: "#241F21",
  softDark: "#282828",
  midDark: "#1D1D1D",
  bgGray: "#E6E6E6",
  strongDark: "#121212",
  mediumGray: "#8A8A8A",
  darkGray: "#4F4F4F",
  lightGray: "#C4C4C4",
  red10: "rgba(227, 25, 75, 0.1)",
  assistantBG: "#E3194B",
  softGray: "#EFEFEF",
  lightCoral: "#E9476F",
};
