// src/services/api/settings.ts
import { BaseApiService } from './base';
import { ApiResponse, SettingsResponse, UpdateSettingsRequest, Settings } from '../../types';

export class SettingsApiService extends BaseApiService {

  /**
   * Get user settings
   */
  async getSettings(): Promise<ApiResponse<SettingsResponse>> {
    return this.api.get<unknown, ApiResponse<SettingsResponse>>('/settings');
  }

  /**
   * Update user settings
   */
  async updateSettings(request: UpdateSettingsRequest): Promise<ApiResponse<SettingsResponse>> {
    return this.api.put<unknown, ApiResponse<SettingsResponse>>('/settings', request);
  }

  /**
   * Get application configuration
   */
  async getConfig(): Promise<ApiResponse<Settings>> {
    return this.api.get<unknown, ApiResponse<Settings>>('/config');
  }
}

// Create a singleton instance
export const settingsApiService = new SettingsApiService();