import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  SwipeableDrawer,
  TextField,
  useTheme,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { motion } from "framer-motion";
import { ArrowDownWideNarrow, ArrowUpWideNarrow, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useThreadStore } from "../../store/threadStore";
import { COLORS } from "../../theme/colors";
import { Thread } from "../../types/thread.types";
import BotFace from "../Icons/BotFace/BotFace";
import SearchIcon from "../Icons/SearchIcon/SearchIcon";
import ThreadIcon from "../Icons/ThreadIcon/ThreadIcon";

interface ThreadsListProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onNewChat: () => void;
}

const ThreadsList: React.FC<ThreadsListProps> = ({
  anchorEl,
  onClose,
  onNewChat,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { threadId } = useParams();
  const {
    threads = [],
    setCurrentThread,
    fetchThreads,
    deleteThread,
  } = useThreadStore();

  // State for delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState<Thread | null>(null);
  const [sortDescending, setSortDescending] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  // Filter out threads with undefined IDs and ensure uniqueness
  const userThreads = threads.filter((thread): thread is Thread => {
    return Boolean(thread && thread._id);
  });

  const sortThreads = (threads: Thread[]) => {
    return [...threads].sort((a, b) => {
      const dateA = new Date(a.updatedAt).getTime();
      const dateB = new Date(b.updatedAt).getTime();
      return sortDescending ? dateB - dateA : dateA - dateB;
    });
  };

  useEffect(() => {
    if (anchorEl) {
      setIsFetching(true);
      fetchThreads().finally(() => setIsFetching(false));
    } else {
      setSearchText("");
    }
  }, [anchorEl, fetchThreads]);

  const handleChatSelect = (thread: Thread) => {
    if (!thread._id || thread._id === threadId) return;
    onClose();
    setCurrentThread(thread);
    navigate(`/app/chat/${thread._id}`);
  };

  const handleClear = () => {
    setSearchText("");
  };

  const filterBySearchValue = (data: Thread[], search: string) => {
    if (search.length < 3) {
      return data;
    }
    const query = search.toLowerCase();
    return data.filter((item) => item.title.toLowerCase().includes(query));
  };

  const handleDeleteClick = (event: React.MouseEvent, thread: Thread) => {
    event.stopPropagation();
    setThreadToDelete(thread);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!threadToDelete?._id) return;

    try {
      await deleteThread(threadToDelete._id);
      setDeleteModalOpen(false);
      toast.success("Thread deleted successfully");

      // Handle navigation after deletion
      if (threadToDelete._id === threadId) {
        // If we deleted the active thread
        if (userThreads.length <= 1) {
          // If this was the last thread, redirect to new chat
          setCurrentThread(null);
          // Use replace to prevent back navigation
          navigate("/app/chat/new", { replace: true });
        } else {
          // Find the next available thread
          const nextThread = userThreads.find(
            (t) => t._id !== threadToDelete._id
          );
          if (nextThread) {
            setCurrentThread(nextThread);
            navigate(`/app/chat/${nextThread._id}`);
          }
        }
      }
    } catch {
      toast.error("Failed to delete thread");
    }
  };

  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const filteredThreads = filterBySearchValue(userThreads, searchText);
  const filteredAndSortedThreads = sortThreads(filteredThreads);
  const isThreadsEmpty = filteredAndSortedThreads.length === 0;

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={Boolean(anchorEl)}
        onClose={onClose}
        onOpen={() => undefined}
        sx={{
          zIndex: 1100,
          "& .MuiDrawer-paper": {
            width: "394px",
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.threadsList,
          },
        }}
      >
        <div className="flex flex-col h-full bg-inherit">
          <div className="h-16 flex-shrink-0 " />
          <div>
            {userThreads.length !== 0 && (
              <Box
                className="flex flex-row justify-between px-4 py-2 pe-1 gap-2 border-b"
                style={{ borderColor: theme.palette.text.contrastDisable }}
              >
                <TextField
                  inputProps={{
                    className: "font-main",
                  }}
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    sx: {
                      backgroundColor: theme.palette.background.threadBox,
                      color: "#8A8A8A",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          fill={theme.palette.background.searchIcon}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: searchText ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear search input"
                          onClick={handleClear}
                          edge="end"
                        >
                          <X color={theme.palette.background.searchIcon} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                  sx={{ width: "100%" }}
                />
                <IconButton
                  size="small"
                  onClick={() => setSortDescending(!sortDescending)}
                  sx={{
                    color: COLORS.darkGray,
                    "&:hover": {
                      backgroundColor: "transparent",
                      "& svg": {
                        color: COLORS.assistantBG,
                        transition: "color 0.2s ease-in-out",
                      },
                    },
                  }}
                  title={
                    sortDescending
                      ? "Change to oldest messages first"
                      : "Change to newest messages first"
                  }
                >
                  {sortDescending ? (
                    <ArrowDownWideNarrow size={24} />
                  ) : (
                    <ArrowUpWideNarrow size={24} />
                  )}
                </IconButton>
              </Box>
            )}
          </div>
          <div className="flex-1 overflow-y-auto">
            <Box height={"100%"}>
              {isFetching ? (
                <Box sx={{ width: "100%" }} key="loading-progress">
                  <LinearProgress />
                </Box>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="h-full"
                  key="threads-container"
                >
                  {isThreadsEmpty ? (
                    <Box className="text-center h-full flex flex-col justify-center items-center font-Barlow">
                      <BotFace fill={theme.palette.text.topicBoxText} />
                      <p
                        className="font-medium text-lg "
                        style={{ color: theme.palette.text.headerMainText }}
                      >
                        No threads yet
                      </p>
                      <p
                        className="font-normal text-base mb-6"
                        style={{ color: theme.palette.text.noThreadsText }}
                      >
                        Start a new conversation
                      </p>
                      <Button
                        variant="outlined"
                        onClick={onNewChat}
                        style={{
                          borderColor: theme.palette.text.mainText,
                          color: theme.palette.text.mainText,
                          fontFamily: "Barlow",
                        }}
                      >
                        New conversation
                      </Button>
                    </Box>
                  ) : (
                    filteredAndSortedThreads.map((thread, index) => (
                      <Box
                        key={thread._id || `thread-${index}`}
                        onClick={() => handleChatSelect(thread)}
                        className={`cursor-pointer border-l-4 p-3 relative group ${
                          thread._id === threadId
                            ? `border-coral`
                            : "border-transparent"
                        }`}
                        sx={{
                          "&:hover": {
                            backgroundColor: theme.palette.background.threadBox,
                          },
                          borderBottom: `1px solid ${theme.palette.text.contrastDisable}`,
                        }}
                      >
                        <div className="flex flex-row gap-2 items-center">
                          <div className="w-[20px]">
                            <ThreadIcon />
                          </div>
                          <div className="flex-1 overflow-hidden">
                            <Box
                              className="line-clamp-2 overflow-hidden text-ellipsis break-words font-main font-medium transition-colors duration-200"
                              component="p"
                              sx={{
                                color: theme.palette.text.headerMainText,
                                ".group:hover &": {
                                  color: theme.palette.text.messageUser,
                                  transition: "color 0.2s ease-in-out",
                                },
                              }}
                            >
                              {thread.title}
                            </Box>
                            <p className="text-sm text-mediumGrey">
                              {formatDistanceToNow(new Date(thread.updatedAt), {
                                addSuffix: true,
                              })}
                            </p>
                          </div>
                          <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                            <X
                              color="#8A8A8A"
                              size={26}
                              data-testid="delete-thread-icon"
                              onClick={(e) => handleDeleteClick(e, thread)}
                            />
                          </div>
                        </div>
                      </Box>
                    ))
                  )}
                </motion.div>
              )}
            </Box>
          </div>
        </div>
      </SwipeableDrawer>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableRestoreFocus
        container={document.body}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "1.25rem",
            paddingBottom: "0rem",
            backgroundColor: "inherit",
          }}
        >
          {"Delete Thread?"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "inherit" }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this thread? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "1.25rem",
            paddingRight: "1.25rem",
            paddingBottom: "1rem",
            backgroundColor: "inherit",
          }}
        >
          <Button
            onClick={() => setDeleteModalOpen(false)}
            sx={{
              fontWeight: 400,
              color: theme.palette.text.mainText,
              border: `1px solid ${theme.palette.text.mainText}`,
              backgroundColor: "inherit",
              fontFamily: "Barlow",
              "&:hover": {
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#444"
                    : "rgba(0, 0, 0, 0.08)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            sx={{
              fontWeight: 400,
              backgroundColor: "#ed1854",
              border: "1px solid #ed1854",
              color: "#fff",
              fontFamily: "Barlow",
              "&:hover": {
                backgroundColor: "#d41549",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ThreadsList;
