import React from "react";
import { Outlet } from "react-router-dom";
import { AnimatedLogo } from "../components/AnimatedLogo/AnimatedLogo";

const AuthLayout: React.FC = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat overflow-hidden relative"
      style={{
        backgroundImage: `url('/assets/images/main_bg_2.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="h-screen w-full overflow-hidden backdrop-blur-sm bg-black/30">
        <div className="absolute top-0 left-0 w-full">
          <div className="w-full max-w-screen-xl mx-auto px-4">
            <div className="py-4">
              <AnimatedLogo />
            </div>
          </div>
        </div>

        <div className="absolute inset-0 flex items-center justify-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
