import { MessageRequest, MessageResponse } from "../../types/message.types";
import { BaseApiService } from "./base";

/**
 * API CHANGE NOTICE:
 * ----------------------------------------------------------------------
 * As of version X.X.X, the API endpoint for fetching thread messages has been updated:
 * 
 * - The `role` and `functionCall` parameters have been replaced with a single `functionData` parameter
 * 
 * - Use `functionData=false` to get regular conversation messages (system, user, assistant)
 *   Previously: role=user,assistant,system
 * 
 * - Use `functionData=true` to get function-related messages (both function calls and responses)
 *   Previously: role=function or functionCall=true
 * 
 * - Default behavior (no parameter): returns regular conversation messages
 * ----------------------------------------------------------------------
 */

// Define options for getting messages
export interface GetMessagesOptions {
  functionData?: boolean;
  limit?: number;
}

export class MessageApiService extends BaseApiService {
  /**
   * Send a message to the AI agent
   */
  async sendMessage(request: MessageRequest): Promise<MessageResponse> {
    return this.api.post<MessageRequest, MessageResponse>("/message", request);
  }

  /**
   * Send a message with streaming response
   */
  async sendStreamingMessage(request: MessageRequest): Promise<Response> {
    return this.api.post("/message/stream", request, {
      responseType: 'stream',
      headers: {
        'Accept': 'text/event-stream'
      }
    });
  }

  /**
   * Send a message to be processed by HuggingFace models
   */
  async sendHuggingFaceMessage(request: MessageRequest): Promise<MessageResponse> {
    return this.api.post<MessageRequest, MessageResponse>("/message/huggingface", request);
  }

  /**
   * Get messages by thread ID
   * @param threadId The ID of the thread
   * @param options Optional parameters for filtering messages 
   */
  async getMessagesByThreadId(threadId: string, options?: GetMessagesOptions): Promise<MessageResponse> {
    const params = new URLSearchParams();
    
    // Handle limit parameter
    if (options?.limit) {
      params.append('limit', options.limit.toString());
    }
    
    // Handle functionData parameter
    if (options?.functionData !== undefined) {
      params.append('functionData', options.functionData.toString());
    }
    
    const queryString = params.toString();
    const url = `/threads/${threadId}/messages${queryString ? `?${queryString}` : ''}`;
    
    return this.api.get<unknown, MessageResponse>(url);
  }

  /**
   * Rate a message
   */
  async rateMessage(messageId: string, rating: number): Promise<void> {
    await this.api.post(`/messages/${messageId}/rate`, { rating });
  }
}

// Create a singleton instance
export const messageApiService = new MessageApiService();
