// src/services/api/index.ts
import { SettingsApiService } from './settings';
import { ThreadsApiService } from './threads';
import { UserApiService } from './users';
import { MessageApiService } from './messages';
import { DocumentApiService } from './documents';
import { EventSourceApiService } from './event-source';
import { LoggingApiService as _LoggingApiService, loggingApiService } from './logging';

export * from './base';
export * from './users';
export * from './settings';
export * from './threads';
export * from './messages';
export * from './documents';
export * from './event-source';
export * from './logging';

// Export convenience object with all services
export const apiServices = {
  user: new UserApiService(),
  settings: new SettingsApiService(),
  threads: new ThreadsApiService(),
  message: new MessageApiService(),
  documents: new DocumentApiService(),
  eventSource: new EventSourceApiService(),
  logging: loggingApiService,
};